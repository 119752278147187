import types from './types'
// Set initial state
const initialState = {};

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_USER:
            return {
                ...state,
                ...action.payload
            };
        case types.SET_USER:
            if (action.payload) {
                return {
                    ...state,
                    ...action.payload
                }
            }
            break;
        case types.RESET:
            return {
                ...initialState,
                user: null
            };
        default :
            return state;
    }
}
