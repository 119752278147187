import React from 'react';
import {Field} from "redux-form";
import InputField from "./InputField";
import {isAlpha, required, isIDNumber} from "../../services/validators";
import '../../styles/childFields.scss'
import DatePicker from "./DatePicker";
import moment from "moment";

function ChildFields(props) {
    return (
        <div className="childFields">
            <div className="label">
                ילד {props.label}
            </div>
            <div className="fields">
                <Field name={`${props.name}.firstName`}
                       type="text"
                       component={InputField}
                       validate={[isAlpha, required]}
                       label="שם פרטי"
                       placeholder="ישראל"
                />
                <Field name={`${props.name}.lastName`}
                       type="text"
                       component={InputField}
                       validate={[isAlpha, required]}
                       label="שם משפחה"
                       placeholder="ישראלי"
                />
                <Field name={`${props.name}.birthDate`}
                       component={DatePicker}
                       parse={(value) => moment(value).valueOf()}
                       validate={[required]}
                       label="תאריך לידה"
                       placeholder="dd/mm/yyyy"
                />
                <Field name={`${props.name}.idCard.number`}
                       type="text"
                       component={InputField}
                       validate={[isIDNumber, required]}
                       label="תעודת זהות"
                       style={{textAlign: 'center'}}
                       placeholder="* ********"
                />
            </div>
        </div>
    );
}

export default ChildFields;