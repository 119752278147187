import AppConfig from '../constants/Config';
import moment from 'moment';
import HTTP from './http'

const objectToArray = (obj) => {
    if (obj) return Object.entries(obj).map((e) => e[1]);
    return obj
};
const normalize = (obj) => {
    return {
        byId: {
            ...obj
        },
        allIds: objectToArray(obj)
    }
};
const formatFunction = (value) => {
    return Number(parseFloat(Math.round(value * 100) / 100).toFixed(2));
};
const formatRoundNumber = (value) => {
    return value > 999 ? Number(parseFloat(value / 1000).toFixed(1)) + 'K' : formatFunction(value)
};
const formatNumber = (value) => {
    return parseFloat(Math.round(value * 100) / 100).toFixed(2);
};
const isUrl = url => /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/.test(url);
const addAssetPrefix = (asset) => `${AppConfig.server.url}/${AppConfig.server.assetPrefix}/${asset}`;
const numberWithCommas = (num) => {
    return formatFunction(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const splitArrayIntoChunks = (arr, size) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i = i + size) {
        chunks.push(arr.slice(i, i + size));
    }
    return chunks;
};
const setFieldByPath = (object, path, value) => {
    let a = path.split(/[\[\]\.]/).filter(i => i);
    let o = object;
    for (let i = 0; i < a.length - 1; i++) {
        let n = a[i];
        if (n in o) {
            o = o[n];
        } else {
            o[n] = {};
            o = o[n];
        }
    }
    o[a[a.length - 1]] = value;
}
const getFieldByPath = (object, path) => {
    var a = path.split(/[\[\]\.]/).filter(i => i);
    var o = object;
    for (var i = 0; i < a.length - 1; i++) {
        var n = a[i];
        if (n in o) {
            o = o[n];
        } else {
            o[n] = {};
            o = o[n];
        }
    }
    return o[a[a.length - 1]];
}
const calculateDateDifference = (date) => {
    let diff, res;
    diff = moment().diff(moment(date), 'years');
    if (diff > 0) {
        res = diff > 0 ? `${diff} years` : `${diff} year`;
        res += ' ago'
    } else {
        diff = moment().diff(moment(date), 'months');
        if (diff >= 1) {
            res = diff > 1 ? `${diff} months` : `${diff} month`;
            res += ' ago'
        } else {
            diff = moment().diff(moment(date), 'weeks');
            if (diff > 0) {
                res = diff > 1 ? `${diff} weeks` : `${diff} week`;
                res += ' ago'
            } else {
                diff = moment().diff(moment(date), 'days');
                if (diff > 0) {
                    res = diff > 1 ? `${diff} days` : `${diff} day`;
                    res += ' ago'
                } else {
                    res = 'Today';
                }
            }
        }
    }
    return res;
}
const searchList = (inputValue = '', array) => {
    return array.filter(i =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
};
const sendEmail = async (name, email, message) => {
    await HTTP.post(`api/util/sendEmail`, {name, email, message});
};
const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView();
    }
};
const selectFormat = (options) => ((stored) => options.filter(option => option.value === stored));
const selectParse = (item) => item.value;
const cityFormat = (options) => ((stored) => Object.values(options).filter(option => option.label === stored));
const cityParse = (item) => item ? item.label : null;
const multiSelectFormat = (options) => ((storedArray) => options.filter(option => storedArray ? storedArray.includes(option.value) : false));
const multiSelectParse = (array) => array ? array.map((item) => item.value) : [];
const dateParse = (value) => value ? moment(value).valueOf() : null;
export {
    normalize,
    objectToArray,
    addAssetPrefix,
    isUrl,
    numberWithCommas,
    splitArrayIntoChunks,
    setFieldByPath,
    getFieldByPath,
    formatNumber,
    formatRoundNumber,
    calculateDateDifference,
    searchList,
    scrollToElement,
    sendEmail,
    selectFormat,
    selectParse,
    cityFormat,
    cityParse,
    multiSelectFormat,
    multiSelectParse,
    dateParse,
}
