import action from '../action.js'
import types from './types'

import {getDataFromServer, getEnumsFromServer} from "../../services/app";

export function getData() {
    return action(async dispatch => {
        let data = await getDataFromServer();
        dispatch({type: types.GET_DATA, payload: data});
        return data;
    })
}

export function getEnums() {
    return action(async dispatch => {
        let enums = await getEnumsFromServer();
        dispatch({type: types.GET_ENUMS, payload: enums});
        return enums;
    })
}

export function toggleModal(visible) {
    return action(async dispatch => {
        dispatch({type: types.TOGGLE_MODAL_VISIBLE, payload: visible});
    })
}

export function toggleSidebar(opened) {
    return action(async dispatch => {
        dispatch({type: types.TOGGLE_SIDEBAR, payload: opened});
    })
}

export function toggleLoader(status) {
    return action(async dispatch => {
        dispatch({type: types.TOGGLE_LOADER, payload: status});
    })
}
