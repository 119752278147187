import types from './types'
// Set initial state
const initialState = {};

export function standReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CURRENT_CAR:
            return {
                ...state,
                ...action.payload
            };
        default :
            return state;
    }
}
