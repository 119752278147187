import Http from "./http";


const getCurrentCarFromStand = async (branchId, standId) => {
    return Http.get(`stands/getCurrentCar/${branchId}/${standId}`);
}

const removeCurrentCarFromStand = async (branchId, standId) => {
    return Http.get(`stands/removeCurrentCar/${branchId}/${standId}`);
}

export {
    getCurrentCarFromStand,
    removeCurrentCarFromStand
}
