import React from 'react';
import logo from '../images/logo.svg';
import '../styles/headerUser.scss';
import {
    withRouter,
    Link,
    NavLink,
} from "react-router-dom";
import userIcon from "../icons/user-icon.svg";

class Header extends React.Component {

    render() {
        return (
            <div className="headerHolder">
                <div className={"topbar-container"}>
                </div>
                <div className={"logo-container"}>
                    <div className={"logo-container-redline"}>
                        <img src={logo} className={"logo"}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Header);
