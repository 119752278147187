import React from 'react'
import moment, {invalid} from "moment";
import DatePicker, {registerLocale} from "react-datepicker";
import he from 'date-fns/locale/he';
import "../../styles/datepicker.scss";
import {Field} from "redux-form";

registerLocale('he', he);
const DatePickerField = ({showTimeSelect, disabled, dateFormat, timeParams = {}, minDate, maxDate, onBlur, input, type, label, placeholder, labelIcon, required, containerClass = '', meta: {touched, error}}) => {
    const errorClass = touched && error ? 'error-color' : '';
    const containerClasses = ['input-group', containerClass, errorClass];

    const {minTime, maxTime, includeTimes, timeIntervals} = timeParams;

    const isWeekday = date => {
        const day = date.getDay();
        return day !== 6;
    };


    return (
        <div className={containerClasses.join(' ')}>
            <div className="label-input">
                <div className="label-wrapper">
                    {labelIcon && <img src={labelIcon} alt=""/>}
                    <label>{required ? '*' : ''}{label}</label>
                </div>
                <DatePicker
                    locale='he'
                    dateFormat={dateFormat || "dd/MM/yyyy | HH:mm"}
                    selected={input.value}
                    onChange={input.onChange}
                    disabled={disabled}
                    className="input-field datepicker"
                    showMonthDropdown
                    showYearDropdown
                    minDate={minDate}
                    maxDate={maxDate}
                    filterDate={isWeekday}
                    showTimeSelect={showTimeSelect}
                    minTime={minTime}
                    maxTime={maxTime}
                    includeTimes={includeTimes}
                    timeIntervals={includeTimes && includeTimes.length > 0 ? timeIntervals : 30}
                    dropdownMode="select"
                    placeholderText={placeholder}
                    onBlur={input.onBlur}
                />
            </div>
            {
                touched && error && <div className='input-error-message'>{error}</div>
            }
        </div>
    )
};
export default DatePickerField;
