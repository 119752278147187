import {connect} from 'react-redux';

// Actions
import {getData, getEnums, toggleLoader} from '../store/app/actions';
import {getUser} from '../store/user/actions';
import {getUserAppointments} from '../store/appointments/actions';

// The component we're mapping to
import AppBoot from './AppBoot';

// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
    user: state.user,
    data: state.app.data
});

// Any actions to map to the component?
const mapDispatchToProps = {
    getData,
    getEnums,
    getUser,
    getUserAppointments,
    toggleLoader
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBoot)
