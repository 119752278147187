import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {HashRouter as Router} from "react-router-dom";

import {getData, toggleLoader} from "./store/app/actions";

import './App.scss';
import AppBoot from "./AppBoot/AppBootContainer";
import Routes from "./constants/Routes"
import Spinner from "./components/Spinner";

function App(props) {
  // const [ data, setData ] = useState(false);
  //
  // const fetchData = async () => {
  //   const response = await props.getData();
  //   setData(true);
  // };
  //
  // useEffect(() => {
  //   fetchData(data); 
  // }, [data]);

  const {showLoader, toggleLoader, user} = props;
  return (
      <div className="App">
        {showLoader && <Spinner />}
        <Router>
          <AppBoot toggleLoader={toggleLoader}>
            <Routes user={user}/>
          </AppBoot>
        </Router>
      </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.app.data,
  showLoader: state.app.showLoader
});

const mapDispatchToProps = {
  getData,
  toggleLoader
};

export default connect(mapStateToProps, mapDispatchToProps)(App)
