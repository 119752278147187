import React from 'react'
import {Redirect, Route, Switch} from "react-router-dom";
import Membership from "../screens/guest/Membership";
import Services from "../screens/user/Services/Services";
import History from "../screens/user/History/History";
import Profile from "../screens/user/Profile/Profile";
import LoginScreen from "../screens/guest/LoginScreen";
import LayoutUser from "../components/LayoutUser";

export default (props) => {
    return (
        <Switch>
            <Route path='/:branchId/:standId/join' component={Membership} />
            {/*<Route exact path='/services' component={Services}/>*/}
            {/*<Route exact path='/history' component={History}/>*/}
            {/*<Route exact path='/profile' component={Profile}/>*/}

            {/*<Route exact path='/membership' component={Membership}/>*/}
            <Route exact path='/login' component={LoginScreen}/>
            <Route exact path='/signup' component={Membership}/>
            <PrivateRoute user={props.user} data={props.data}/>
        </Switch>
    )
}
const PrivateRoute = ({user, data}) => {
    // debugger;
    return (
        <Route
            render={(props) => !!(user && user._id)
                ? user.status === 'active' ? <ClientRoutes {...props} user={user} data={data}/>
                : <Redirect to={{pathname: `/signup`, state: {from: props.location}}} />
                : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
            }
        />
    )
};
const ClientRoutes = ({user, data}) => {
    return (
      <LayoutUser>
            <Switch>
                <Route exact path='/services' component={Services}/>
                <Route exact path='/history' component={History}/>
                <Route exact path='/profile' component={Profile}/>
                <Redirect to={{pathname: '/services'}}/>
            </Switch>
      </LayoutUser>
    )
};
