import React, {useState, Component} from 'react';
import {connect} from "react-redux";
import Popup from "reactjs-popup";
import { reset } from 'redux-form'

import {getUser, register} from "../../store/user/actions";
import {getCurrentCar} from "../../store/stand/actions";

import {removeCurrentCarFromStand} from "../../services/stand";
import './membership.scss'
import logo from '../../images/logo.svg';

import StyleButton from "../../components/StyleButton";
import MembershipForm from "../../components/Forms/Personal/MembershipForm";

const CAR_POLLING_INTERVAL = 2000; //interval for current car polling


class Membership extends Component {

    constructor(props) {
        super(props);
        this.state = {showSuccessPopup: false};
    }

    handleSubmit = async (form, dispatch) => {
        let {fullname, phone, number, email, maker, model, mileage, testDate, cars} = form;
        let carDetails = {
            number: number.split('-').join(''),
            maker: maker && typeof maker === 'object' ? maker.label : maker,
            model: maker && typeof model === 'object' ? model.label : model,
            mileage,
            testDate
        };
        let userDetails = {
            fullname,
            phone: phone.split('-').join(''),
            email,
            // cars: cars ? cars.indexOf(number) === -1 ? [...cars, number] : cars : [number],
            status: 'active'
        };

        await this.props.register({userDetails, carDetails});
        this.setState({showSuccessPopup: true});
        dispatch(reset('membership-form'));

        const currantPath = this.props.history.location.pathname;
        if (currantPath === '/signup' || currantPath === '/membership') {
            await this.props.getUser();
            setTimeout(() => {
                this.props.history.replace('/services');
            }, 2000);
        } else {
            const {branchId, standId} = this.props.match.params;
            await removeCurrentCarFromStand(branchId, standId);
        }
    }

    async componentDidMount() {
        const {branchId, standId} = this.props.match.params;
        if (branchId && standId)
            await this.listenForCurrentCar();
    }

    componentWillUnmount () {
        clearInterval(this.carInterval);
    }

    async listenForCurrentCar() {
        this.carInterval = setInterval(this.getCurrentCar.bind(this), CAR_POLLING_INTERVAL);
    }

    async getCurrentCar() {
        const {branchId, standId} = this.props.match.params;
        await this.props.getCurrentCar(branchId, standId);
    }

    closeSuccessPopup = () => {
        const currantPath = this.props.history.location.pathname;
        if (currantPath === '/signup' || currantPath === '/membership') {
            this.props.history.replace('/services');
        } else {
            this.setState({showSuccessPopup: false})
        }
    }

    render() {
        return (
            <div className="membershipHolder">
                <div className="coverImage"/>
                <div className="memberForm">
                    <img className="logo" src={logo} alt={'טכנו טסט'}/>
                    <div className={"title"}>הצטרפות לחברי המועדון
                        של טכנו טסט
                    </div>
                    <div className={"sub-title"}>אנא מלא את הפרטים האישיים</div>
                    <MembershipForm onSubmit={this.handleSubmit.bind(this)} />
                </div>
                {this.state.showSuccessPopup ?
                    <Popup open={true} modal closeOnDocumentClick={false} onClose={this.closeSuccessPopup}>
                        {close => (
                            <div className="modal congrats">
                                <img className="logo" src={logo} alt={'טכנו טסט'}/>
                                <div className={"title"}>טכנו טסט מברכת אותך על הצטרפותך למועדון הלקוחות</div>
                                <StyleButton label='סגור'
                                             callback={close}
                                             color='#0067a5'
                                             customStyle={{
                                                 marginTop: "60rem",
                                                 justifySelf: 'center',
                                                 alignSelf: 'center',
                                                 fontSize: "22rem"
                                             }}
                                />
                            </div>
                        )}
                    </Popup>
                    : null
                }
            </div>
        );
    }

}

const mapStateToProps = ({stand}) => ({
    user: stand.user,
    car: stand.car
});

const mapDispatchToProps = {
    register,
    getCurrentCar,
    getUser
};
export default connect(mapStateToProps, mapDispatchToProps)(Membership)
