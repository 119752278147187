import React, {useRef} from 'react'
import {components} from 'react-select';
import AsyncSelect from 'react-select/async';
import dropdownArrow from '../../icons/down-arrow-icon.svg'

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={dropdownArrow} alt=""/>
        </components.DropdownIndicator>
    );
};
const Autocomplete = ({
                          value, input, loadOptions, options, defaultValue = null, label, placeholder,
                          inputIcon, labelIcon, required, isMulti, dataTip = '', isDisabled, defaultOptions,
                          showDropdownIndicator = true, containerClass = '', getRef, noOptionsMessage, loadingMessage
                          , width = "100rem", onCustomChange = () => {},
                          meta: {touched, error}
                      }) => {
    const errorClass = touched && error ? 'error-color' : '';
    const containerClasses = ['input-group', containerClass, errorClass];
    const autocompleteRef = useRef(null);
    if (getRef) {
        getRef(autocompleteRef);
    }
    const inputStyle = {
        fontSize: '16rem',
        letterSpacing: '0.37rem',
        color: '#0067a5',
        padding: '0',
        textAlign: 'start'
    };
    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            ...inputStyle,
            color: '#a19a9a'
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: 0
        }),
        singleValue: (provided) => ({
            ...provided,
            ...inputStyle,
        }),
        option: (provided, state) => ({
            ...provided,
            ...inputStyle,
            padding: '10rem',
            color: state.isSelected ? 'white' : '#5b5a5f',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            border: '1rem solid #ddd',
            borderRadius: '17rem',
            alignItems: 'center'
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            fontSize: '15rem',
            fontWeight: '300',
            color: '#45434b'
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            margin: '5rem',
            color: '#ffa700',
            border: '1rem solid #ffa700',
            borderRadius: '50%',
            padding: '0',
            width: '15rem'
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            padding: 0,
            marginRight: '5rem',
            height: '20rem',
            display: showDropdownIndicator ? 'flex' : 'none'
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            visibility: 'hidden',
        }),
        control: (provided) => ({
            display: 'flex',
            flexDirection: 'row',
            minWidth: width,
            height: '20rem',
            alignItems: 'center',
            padding: '4rem 10rem',
        }),
    };
    return (
        <div className={containerClasses.join(' ')}>
            <div className="label-input">
                <div className="label-wrapper">
                    {labelIcon && <img src={labelIcon} alt=""/>}
                    <label htmlFor={input.name}>{required ? '*' : ''}{label}</label>
                </div>
                <div className="input-wrapper" data-tip={dataTip}>
                    {inputIcon && <div className="input-icon-wrapper">
                        <img width={17} height={17} src={inputIcon} alt=""/>
                    </div>}
                    <AsyncSelect
                        ref={autocompleteRef}
                        name={input.name}
                        value={input.value}
                        styles={customStyles}
                        isDisabled={!!isDisabled}
                        cacheOptions
                        isMulti={!!isMulti}
                        loadOptions={loadOptions}
                        defaultOptions={defaultOptions}
                        defaultValue={defaultValue}
                        noOptionsMessage={noOptionsMessage}
                        loadingMessage={loadingMessage}
                        placeholder={placeholder}
                        components={{DropdownIndicator}}
                        errorState={touched && error}
                        isClearable={true}
                        openMenuOnFocus={true}
                        openMenuOnClick={false}
                        hasIcon={!!inputIcon}
                        // openMenuOnClick={false}
                        onChange={(value) => {input.onChange(value); onCustomChange(value)}}
                        onBlur={() => input.onBlur(value)}
                    />
                </div>
            </div>
            {touched && error && <div className='input-error-message'>{error}</div>}
        </div>
    )
};
export default Autocomplete;
