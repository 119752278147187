import React from 'react';
import "../styles/card.scss";
import {useRouteMatch, withRouter} from "react-router-dom";

function Card({
                  image = "",
                  label = "הכנס שם",
                  callback = false,
                  ...props
              }) {
    let {path, url} = useRouteMatch();
    let goTo = () => {
        props.history.push(`${url}${callback}`);
    };
    let linkStyle = {
            backgroundColor: "#ffffff"
        }
    ;
    return (
        <div className="cardHolder">
            <div onClick={callback ? ()=>callback(props.card) : () => null} className={props.isSelected? "card selected" : "card"}
                 style={callback ? {...linkStyle, cursor: "pointer"} : {}}>
                <div className="cardImageHolder">
                    <img src={image} className={"cardImage"} alt=""/>
                </div>

                <div className="cardLabel">
                    {label}
                </div>

            </div>
        </div>
    );
}

export default withRouter(Card);