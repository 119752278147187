import React from 'react'
import {change, Field, formValueSelector, reduxForm} from 'redux-form'
import InputField from '../../InputFields/InputField'
import StyleButton from '../../StyleButton';
// import '../../styles/form.scss';
// Validators
import {isNumber, normalizeNumber, normalizeCar, required} from "../../../services/validators";
import {getCarBrandAutocomplete, getCarModelAutocomplete} from "../../../services/car";
import CustomSelect from "../../InputFields/Select";
import {connect} from "react-redux";

let NewCarForm = props => {
    const {handleSubmit, error, invalid, carBrands, maker, updateField} = props;
    return (
        <form className="form membershipForm">
            <div className="heading">הוספת רכב חדש</div>
            <div className='inputs-wrapper'>
                <div className="together">
                    <Field name={`number`}
                           type="text"
                           component={InputField}
                           style={{direction: 'ltr', textAlign: 'right'}}
                           validate={[isNumber, required]}
                           label="מספר רכב"
                           placeholder="*מספר רכב"
                           normalize={normalizeCar}
                        // format={normalizeCar}
                        // parse={(value, name) => value ? value.split('-').join('') : value}
                    />
                    <Field name={`mileage`}
                           type="number"
                           style={{direction: 'ltr', textAlign: 'right'}}
                           component={InputField}
                           validate={[isNumber]}
                           label="קילומטראז׳"
                           placeholder="קילומטראז׳"
                           // normalize={normalizeNumber}
                    />
                </div>
                <div className="together">
                    <Field
                        name="maker"
                        label="יצרן"
                        placeholder="יצרן"
                        showDropdownIndicator={false}
                        onCustomChange={() => updateField('new-car-form', 'model', '')}
                        // defaultOptions={getCarBrandAutocomplete('', carBrands)}
                        options={getCarBrandAutocomplete('', carBrands)}
                        // loadOptions={searchToken => getCarBrandAutocomplete(searchToken, carBrands)}
                        noOptionsMessage={() => 'לא נמצאו תוצאות'}
                        validate={[required]}
                        // parse={(value, name) => value ? value.label : value}
                        component={CustomSelect}/>
                    <Field
                        name="model"
                        label="דגם"
                        placeholder="דגם"
                        showDropdownIndicator={false}
                        isDisabled={!maker}
                        options={maker && typeof maker === 'object' ? getCarModelAutocomplete('', maker, carBrands) : []}
                        // loadOptions={searchToken => getCarModelAutocomplete(searchToken, maker, carBrands)}
                        noOptionsMessage={() => 'לא נמצאו תוצאות'}
                        validate={[required]}
                        // parse={(value, name) => value ? value.label : value}
                        component={CustomSelect}/>
                </div>
                {error && <div className="form-error">{error}</div>}
                <StyleButton label='הוסף לרשימת הרכבים'
                             // type="submit"
                             callback={handleSubmit}
                             isDisabled={invalid}
                             customStyle={{margin: "40rem 0"}}
                />
            </div>
        </form>
    )
};

const mapDispatchToProps = dispatch => ({
    updateField: (form, field, newValue) => dispatch(change(form, field, newValue))
});

const selector = formValueSelector('new-car-form');// <-- same as form name
const NewCarFormRedux = reduxForm({
    form: 'new-car-form',
    enableReinitialize: true
})(NewCarForm);

const NewCarFormDecorated = connect((state) => {
    const carBrands = state.app.enums.cars;
    const maker = selector(state, `maker`);
    const model = selector(state, `model`);
    return {
        maker: maker ? maker : null,
        // maker: car ? carBrands.find(c=>c.value === car.maker) : null},
        carBrands: carBrands,
    };
}, mapDispatchToProps)(NewCarFormRedux);


export default NewCarFormDecorated;
