import http from './http'

export const getAvailableAppointmentsFromServer = async ({date, branch, service}) => {
    return  http.get(`appointments/available?date=${date}&branch=${branch}&service=${service}`);
}

export const createAppointment = async (appointmentDetails) => {
    return http.post(`appointments/create`, appointmentDetails);
}

export const getMyAppointments = async () => {
    return http.get(`appointments/myAppointments`)
}
