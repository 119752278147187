import React from 'react';
import logoutIcon from '../icons/logout-icon.png';
import '../styles/logoutButton.scss';
import {connect} from "react-redux";
//Actions
import {logout} from '../store/user/actions';

function LogoutButton(props) {
    async function signOutClick() {
        await props.logout({});
    }

    return (
        <img src={logoutIcon} alt="" className="icon" onClick={signOutClick}/>
    );
}

// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    logout,
};
export default connect(mapStateToProps, mapDispatchToProps)(LogoutButton);