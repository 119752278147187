import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import LayoutUser from '../../../components/LayoutUser'
import './servicesScreen.scss'
import Card from "../../../components/Card";
import buyIcon from "../../../icons/cars/buy.svg"
import accidentIcon from "../../../icons/cars/accident.svg"
import frontIcon from "../../../icons/cars/car-front.svg"
import testIcon from "../../../icons/cars/test.svg"
import confirmIcon from "../../../icons/confirm.svg"
import closeIcon from "../../../icons/x-icon.svg"
import advertisement from "../../../images/banner.svg"

import CheckBeforePurchaseForm from "../../../components/Forms/Personal/CheckBeforePurchaseForm";
import TestForm from "../../../components/Forms/Personal/TestForm";
import FrontForm from "../../../components/Forms/Personal/FrontForm";
import AccidentForm from "../../../components/Forms/Personal/AccidentForm";
import PaymentForm from "../../../components/Forms/Personal/PaymentForm";
import Wizard from "../../../components/Wizard";

import moment from "moment";
import Popup from 'reactjs-popup';
import StyleButton from "../../../components/StyleButton";
import SubHeader from "../../../components/SubHeader";
import isMobile from "ismobilejs";
import {getData, getEnums, toggleLoader} from "../../../store/app/actions";
import {getUser} from "../../../store/user/actions";
import {createAppointment, getUserAppointments, getAvailableAppointments} from "../../../store/appointments/actions";
import {connect} from "react-redux";
import AppBoot from "../../../AppBoot/AppBoot";
import {normalizeCar} from "../../../services/validators";

const isMobileOnly = isMobile(window.navigator).phone;

const RequestedServiceDetailsTextBox = ({user, service, form, fontSize}) => (
    <div className={"details-wrapper"} style={{fontSize}}>
        <div className={"text-line"}>
            <div className={"label"}>מכון</div>
            <div className={"value"}>{form.branch ? form.branch.label : null} {isMobileOnly ? '| ' : ''}</div>
        </div>
        <div className={"text-line"}>
            <div className={"label"}>לרכב מס׳</div>
            <div className={"value"}>{normalizeCar(user.cars.find(car => car._id === form.car).number)}</div>
        </div>
        <div className={"text-line"}>
            <div className={"label"}>תאריך</div>
            <div className={"value"}>{moment(form.date).format("DD.MM.YY")} {isMobileOnly ? '| ' : ''}</div>
            <div className={"label"}>שעה</div>
            <div className={"value"}>{moment(form.date).format("HH:mm")}</div>
        </div>
    </div>
)


const ServiceRequestSummary = ({user, service, form}) => (
    isMobileOnly ?
        <div className={"payment-summary-container mobile"}>
            <RequestedServiceDetailsTextBox user={user} service={service} form={form}/>
            <div className={"requested-service-cost"}>
                <div className={"text-line"}>
                    <div className={"label"}>
                        עלות השירות:
                    </div>
                    <div className={"value"} style={{fontSize: '26rem'}}>
                        {service.cost ? "₪" + service.cost : "המחיר יקבע בגמר הטיפול"}
                    </div>
                </div>
            </div>
            <div className={"requested-service-comments"}>
                <div className={"text-line"} style={{display: 'initial'}}>
                    <span className={"label"}>
                        הערות ופרטים נוספים:
                    </span>
                    <span className={"value"}>{service.notes}</span>
                </div>
            </div>
        </div> :
        <div className={"payment-summary-container"}>
            <div className={"image-container"}>
                <img src={service.image} style={{width: "80rem"}}/>
                <div style={{}}>
                    {service.label}
                </div>
            </div>
            <div className={"requested-service-details"}>
                <div className={"headline"}>
                    פרטי השירות המבוקש:
                </div>
                <RequestedServiceDetailsTextBox user={user} service={service} form={form}/>
            </div>
            <div className={"requested-service-cost"}>
                <div className={"headline"}>
                    עלות השירות המבוקש:
                </div>
                <div style={{
                    fontWeight: "bold",
                    fontSize: "35rem",
                    display: "flex",
                    flex: 1,
                    width: "65%",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    {service.cost ? "₪" + service.cost : "המחיר יקבע בגמר הטיפול"}
                </div>
            </div>
            <div className={"requested-service-comments"}>
                <div className={"headline"}>
                    הערות ופרטים נוספים:
                </div>
                <div style={{
                    fontSize: "22rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "10rem",
                    textAlign: "right"
                }}>
                    <div>{service.notes}</div>
                </div>
            </div>
        </div>
)

const PaymentConfirmationModal = ({user, isOpen, service, form, isOnlinePayment, onButtonClicked}) => (
    <Popup open={isOpen} className={"payment-confirmation-modal"} closeOnDocumentClick={false}>
        <img src={confirmIcon} style={{height: "80rem", marginBottom: "20rem"}}/>
        <div className={"headline"}>
            {isOnlinePayment ? "אישור תשלום" : "אישור הזמנה"}
        </div>
        <div className={"text-line"} style={{fontSize: "26rem"}}>
            <div style={{paddingLeft: "5px"}}>תודה, הזמנתך לשירות</div>
            <div style={{paddingLeft: "5px", fontWeight: "bold"}}>{service.label}</div>
            <div style={{paddingLeft: "5px"}}>אושרה ונרשמה במערכת</div>
        </div>
        <div className={"text-line"} style={{fontSize: "26rem"}}>
            פרטי ההרשמה נשלחו אלייך למייל
        </div>
        <div style={{fontWeight: "bold", fontSize: "35rem"}}>
            {service.cost ? "₪" + service.cost : "המחיר יקבע בגמר הטיפול"}
        </div>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", fontSize: "22rem"}}>
            <div className={"text-line"}>
                <div style={{paddingLeft: "10px", fontWeight: "bold"}}>טסט - מספר הזמנה</div>
                <div style={{paddingLeft: "5px"}}>2453</div>
            </div>
            {isOnlinePayment && <div className={"text-line"}>
                <div style={{paddingLeft: "10px", fontWeight: "bold"}}>מספר אישור</div>
                <div style={{paddingLeft: "5px"}}>348-2334</div>
            </div>}
        </div>
        <div className={"requested-service-details"}>
            <div style={{fontSize: "22rem", textDecoration: "underline", marginBottom: "10rem"}}>
                פרטי השירות המבוקש:
            </div>
            <div className={"bottom-container"}>
                <RequestedServiceDetailsTextBox user={user} service={service} form={form} fontSize={"18rem"}/>
                <div className={"requested-service-comments"}>
                    <div className={"text-line"}>
                    <span className={"label"}>
                        הערות ופרטים נוספים:
                    </span>
                        <span className={"value"}>{service.notes}</span>
                    </div>
                </div>
            </div>
        </div>
        <StyleButton label='סיום'
                     type="submit"
                     isDisabled={false}
                     onClick={onButtonClicked}
                     customStyle={{
                         margin: "10rem 5rem",
                         fontSize: "22rem",
                         backgroundColor: "#0067a5",
                         minWidth: "206rem"
                     }}
        />
    </Popup>
)


class Services extends Component {
    constructor (props) {
        super(props);
        this.state = {
            currentFormStage: 0
        };
    }

    async componentDidMount () {
    }

    handleSubmit = (form) => {
        this.setState({currentFormValues: form, currentFormStage: this.state.currentFormStage + 1})
    }

    handlePaymentSubmit = async (form) => {
        let {currentFormValues, currentService} = this.state;
        let appointmentDetails = {
            service: currentService._id,
            car: currentFormValues.car,
            appointmentDate: currentFormValues.date,
            branch: currentFormValues.branch.value,
            status: "Pending Approval",
            onlinePayment: false,
        }
        await this.props.createAppointment({appointmentDetails});
        this.setState({showConfirmationModal: true, paymentFormValues: form})
        this.props.getUserAppointments();
    }

    setCurrentCard = (card) => {
        this.setState({currentService: card})
    }

    closeService = () => {
        this.setState({
            showConfirmationModal: false,
            currentFormStage: 0,
            currentService: null,
            currentFormValues: null,
            paymentFormValues: null
        })
    }

    getAvailableHours = async (formData) => {
        const {date, branch} = formData;
        const {currentService} = this.state;
        if (branch && currentService) {
            const availableHours = await this.props.getAvailableAppointments({
                date: date || new Date(),
                branch,
                service: currentService._id
            });
            return availableHours;
            // this.setState({availableHours});
        }
    }

    goBack = () => {
        let {currentFormStage, currentService} = this.state;
        if (currentFormStage === 0) {
            this.setState({currentFormStage: 0, currentService: null})
        } else {
            this.setState({currentFormStage: currentFormStage - 1});
        }
    }

    navigateTo = (screen) => {
        this.props.history.push(`/${screen}`);
    }

    getServices = () => {
        const {currentService} = this.state;
        const icons = {
            test: testIcon,
            buy: buyIcon,
            front: frontIcon,
            accident: accidentIcon,
        };

        const forms = {
            test: {
                1: <TestForm onSubmit={this.handleSubmit} service={currentService} disableGoBack={true}
                              goBack={this.goBack} getAvailableAppointments={this.getAvailableHours}/>,
                2: <PaymentForm onSubmit={this.handlePaymentSubmit}
                                goBack={this.goBack}/>
            },
            buy: {
                1: <CheckBeforePurchaseForm onSubmit={this.handleSubmit} service={currentService} disableGoBack={true}
                              goBack={this.goBack} getAvailableAppointments={this.getAvailableHours}/>,
                2: <PaymentForm onSubmit={this.handlePaymentSubmit}
                                goBack={this.goBack}/>
            },
            front: {
                1: <FrontForm onSubmit={this.handleSubmit} service={currentService} disableGoBack={true}
                              goBack={this.goBack} getAvailableAppointments={this.getAvailableHours}/>,
                2: <PaymentForm onSubmit={this.handlePaymentSubmit}
                                goBack={this.goBack}/>
            },
            accident: {
                1: <AccidentForm onSubmit={this.handleSubmit} service={currentService} disableGoBack={true}
                              goBack={this.goBack} getAvailableAppointments={this.getAvailableHours}/>
            },
        };

        const services = this.props.services.map(service => {
            return {
                ...service,
                image: icons[service.name],
                formStages: Object.entries(forms[service.name]).map(([key, value]) => {
                    return {id: key, form: React.cloneElement(value, {service})}
                }),
                /*formStages: [
                    {
                        id: 1,
                        form: React.createElement(forms[service.name], {
                                onSubmit: this.handleSubmit,
                                service:service,
                                disableGoBack: true,
                                goBack: this.goBack,
                                getAvailableAppointments: this.getAvailableHours
                        })
                        // form: <CheckBeforePurchaseForm
                        //     onSubmit={this.handleSubmit}
                        //     service={service}
                        //     disableGoBack={true}
                        //     goBack={this.goBack}
                        //     getAvailableAppointments={this.getAvailableHours}
                        //     // availableHours={this.state.availableHours}
                        //     // branches={branches}
                        // />,
                    },
                    {
                        id:2,
                    }
                ],*/
            }
        });
        return services;
    }

    render () {
        let {currentService, currentFormStage, currentFormValues, showConfirmationModal, paymentFormValues} = this.state;
        let isLastStage = currentService && currentFormStage === currentService.formStages.length - 1;

        return (
            <div className="services-screen-container">
                {currentService ? isLastStage ?
                    <div className={"titles-container"}>
                        <div className={"title"}>
                            פרטי השירות
                        </div>
                        <div className={"title-secondary"}>
                            {currentService.label}
                        </div>
                    </div>
                    :
                    <div className={"title-secondary"}>
                        השירות הנבחר
                    </div>
                    :
                    <div className={"titles-container"}>
                        <div className={"title"}>
                            איזה שירות תרצה להזמין?
                        </div>
                        <div className={"title-secondary"}>
                            השירותים שלנו:
                        </div>
                    </div>
                }
                {currentService && currentFormValues && isLastStage && currentService && currentService.name !== 'accident' ?
                    <ServiceRequestSummary user={this.props.user} form={currentFormValues} service={currentService}/>
                    :
                    <div id="cards-container"
                         className={currentService ? "cards-container card-selected" : "cards-container"}>
                        {this.getServices().map((item, key) =>
                            <Card
                                key={key}
                                image={item.image}
                                label={item.label}
                                id={item.id}
                                callback={this.setCurrentCard}
                                isSelected={currentService ? item.id === this.state.currentService.id : false}
                                card={item}
                            />
                        )}
                    </div>}
                {<SubHeader/>}
                {
                    <div className={currentService ? "form-container show" : "form-container"}>
                        <img src={closeIcon} className={"close-form"} onClick={this.closeService} alt="close"/>
                        {currentService && currentService.formStages.length > 1 &&
                        <Wizard currentPage={this.state.currentFormStage}
                                stages={currentService ? currentService.formStages : []}
                        />}
                        {currentService && currentService.name !== 'accident' && <div className={"form-header"}>
                            {isLastStage ?
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <div style={{display: "flex", alignItems: "center",}}>
                                        <div>
                                            עלות השירות
                                        </div>
                                        <div style={{fontWeight: "bold", paddingRight: "5px"}}>
                                            {currentService.cost ? "₪" + currentService.cost : "המחיר יקבע בגמר הטיפול"}
                                        </div>
                                    </div>
                                </div>
                                :
                                "אנא הזן את פרטי השירות שנבחר"
                            }
                        </div>}
                        {currentService && <div className={"commercial"}>
                            <div className={"content"}>{currentService.advertisement}</div>
                        </div>}
                        <div className={"form-fields-container"}>
                            {currentService && React.cloneElement(
                                currentService.formStages[currentFormStage].form,
                                {initialValues: currentFormValues}
                            )}
                            {/*{currentService ? isLastStage ?*/}
                            {/*    <PaymentForm onSubmit={this.handlePaymentSubmit}*/}
                            {/*                 allowOnlinePayment={currentService.allowOnlinePayment}*/}
                            {/*                 goBack={this.goBack}/>*/}
                            {/*    :*/}
                            {/*    React.cloneElement(*/}
                            {/*        currentService.formStages[currentFormStage].form,*/}
                            {/*        {initialValues: currentFormValues}*/}
                            {/*    ) : null }*/}
                            {/*currentService.formStages[currentFormStage].form : null}*/}
                        </div>
                    </div>}
                {showConfirmationModal &&
                <PaymentConfirmationModal user={this.props.user} isOpen={true} service={currentService}
                                          navigateToHistory={() => this.navigateTo('history')}
                                          onButtonClicked={this.closeService}
                                          form={currentFormValues}
                                          isOnlinePayment={currentService.allowOnlinePayment}
                    // isOnlinePayment={paymentFormValues.isOnlinePaymentValue}
                />}
            </div>
        );
    }
}

// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
    user: state.user,
    services: state.app.data.services
});

// Any actions to map to the component?
const mapDispatchToProps = {
    getData,
    getEnums,
    getUser,
    toggleLoader,
    createAppointment,
    getUserAppointments,
    getAvailableAppointments
};

export default connect(mapStateToProps, mapDispatchToProps)(Services)
