import React from 'react';
import '../styles/styleButton.scss'

const StyleButton = ({
                         callback,
                         color = "#0067a5",
                         label = "תלחצו עלי",
                         isDisabled = false,
                         isOutlined = false,
                         customStyle = {},
                         type = 'button',
                         icon,
                         ...rest
                     }) => {
    let myStyle = isDisabled ? {backgroundColor: "#adadad", cursor: "not-allowed"} : {backgroundColor: color};
    myStyle = isOutlined ? {backgroundColor: "#ffffff", color: color, border: "solid 3px " + color} : myStyle;
    return (
        <button className="button" disabled={isDisabled} onClick={callback}
                style={{...myStyle, ...customStyle}} type={type} {...rest}>
            {icon && <img src={icon} />}
            {label}
        </button>
    );
};
export default StyleButton;
