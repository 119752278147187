import React from 'react';
import {NavLink, withRouter} from "react-router-dom";
import '../styles/sidebarUser.scss';

function SideBarUser(props) {
    let pages = props.links.map((item, index) =>
        <NavLink
            key={index}
            className='item'
            activeClassName="active"
            to={`/${item.route}`}>
            <img src={item.icon} alt='' className="image"/>
            <div className="label">
                {item.title}
            </div>
        </NavLink>
    );
    return (
        <div className="sidebar">
            <div className="heading">
                ברוכים הבאים,
            </div>
            <div className="line"/>
            {props.showLinks &&
            <div className="links">
                {pages}
            </div>
            }
        </div>
    );
}

export default withRouter(SideBarUser);