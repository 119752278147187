import types from './types'

// Set initial state
const initialState = {
    data: null,
    enums: null,
    modalVisible: false,
    sidebarOpened: false,
    showLoader: true
};

export function appReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_DATA:
            return {
                ...state,
                data: action.payload
            }

        case types.GET_ENUMS:
            return {
                ...state,
                enums: action.payload
            }

        case types.TOGGLE_MODAL_VISIBLE:
            return {
                ...state,
                loginModalVisible: action.payload
            };

        case types.TOGGLE_SIDEBAR:
            return {
                ...state,
                sidebarOpened: action.payload
            };

        case types.TOGGLE_LOADER:
            return {
                ...state,
                showLoader: action.payload
            };

        default :
            return state;
    }
}
