import React from 'react';
import HeaderUser from "../components/HeaderUser"
import SideBarUser from "./SideBarUser";
import paperIcon from '../icons/paper-icon.svg';
import bookIcon from '../icons/book-icon.svg';
import programIcon from '../icons/program-icon.svg';
import '../styles/layoutUser.scss';
import Footer from "./Footer";
import {getUser, login, logout} from "../store/user/actions";
import {connect} from "react-redux";

function LayoutUser(props) {

    return (
        <div className="layoutUser">
            <HeaderUser logout={props.logout}/>
            <div className="container">
                <div className="content">
                    {props.children}
                </div>
            </div>
            <Footer/>
        </div>
    );
}

// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
    user: state.user
});
// Any actions to map to the component?
const mapDispatchToProps = {
    getUser,
    login,
    logout
};
export default connect(mapStateToProps, mapDispatchToProps)(LayoutUser)
