import React from 'react';
import "../styles/footer.scss";
import {Link} from "react-router-dom";
import logo from "../images/logo.svg";

class Footer extends React.Component {
    render() {
        return (
            <div className="footerHolder">
                <div className="footer">
                    <div className="footerContact">
                        <Link to="/services" className="footerLeftText">
                            {"תנאי שימוש"}
                        </Link>
                        <Link to="/services" className="footerLeftText">
                            {"תקנון"}
                        </Link>
                    </div>
                    <div className="footerMiddle">
                        <img src={logo} className={"footerLogo"}/>
                        <div className={"footerMiddleText"}>
                            {"© כל הזכויות שמורות לטכנו טסט בע\"מ"}
                        </div>
                        <a href={"http://apricode.co.il/"} className={"footerMiddleGrayText"}>
                            {"Powered by APRICODE"}
                        </a>
                    </div>
                    <div className="footerLeft">
                        <div className={"footerLeftTextContainer"}>
                            <a href={"mailto:" + "technotest@walla.com"}>{"technotest@walla.com"}</a>
                            <a href="tel:+972508303051">{"050-830-3051"}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;