import React from 'react'
import {Field, reduxForm} from 'redux-form'
import InputField from '../InputFields/InputField'
import StyleButton from '../StyleButton';
import '../../styles/form.scss';
// Validators
import {isVerificationCode, normalizeCode, required} from '../../services/validators'

let VerificationForm = props => {
    const {handleSubmit, error, invalid} = props;
    return (
        <form className="form" onSubmit={handleSubmit}>
            <div className="heading">
                הזן את קוד האימות שנשלח אליך בהודעת SMS
            </div>
            <div className='inputs-wrapper'>
                <Field name={`verificationCode`}
                       type="text"
                       component={InputField}
                       validate={[required, isVerificationCode]}
                       placeholder="קוד אימות*"
                       normalize={normalizeCode}
                />
                {error && <div className="form-error">{error}</div>}
                <StyleButton label='אשר'
                             type="submit"
                             isDisabled={invalid}
                             customStyle={{margin: "40rem 0"}}
                />
            </div>
        </form>
    )
};
export default VerificationForm = reduxForm({
    form: 'verification',
    // initialValues: {
    //     code: '',
    // }
})(VerificationForm);
