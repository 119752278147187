import http from './http'

export const getDataFromServer = async () => {
    // return await http.get('api/data');
    const branches = await http.get('branches');
    const services = await http.get('services');
    return {branches: branches.data, services: services.data};
}

export const getEnumsFromServer = async () => {
    // const cars = await http.get('cars'); //TODO: fix enums route
    // debugger;
    return {
        "cars": [
            {
                "value": "audi",
                "display": "אאודי",
                "models": {
                    "__0": "100",
                    "__1": "80",
                    "__2": "A1",
                    "__3": "A3",
                    "__4": "A4",
                    "__5": "A5",
                    "__6": "A6",
                    "__7": "A7",
                    "__8": "A8",
                    "__9": "Q3",
                    "__10": "R8",
                    "__11": "RS3",
                    "__12": "RS5",
                    "__13": "RS6",
                    "__14": "RS7",
                    "__15": "S3",
                    "__16": "S4",
                    "__17": "S5",
                    "__18": "S6",
                    "__19": "S7",
                    "__20": "S8",
                    "__21": "TT",
                    "__22": "אולרוד"
                }
            },
            {
                "value": "",
                "display": "אברת'",
                "models": {
                    "__0": "124 ספיידר",
                    "__1": "500",
                    "__2": "500c",
                    "__3": "595",
                    "__4": "595c",
                    "__5": "פונטו איבו"
                }
            },
            {
                "value": "",
                "display": "אוטוביאנקי",
                "models": {
                    "__0": "Y10",
                    "__1": "ג'וניור"
                }
            },
            {
                "value": "",
                "display": "אולדסמוביל",
                "models": {
                    "__0": "אומגה",
                    "__1": "אורורה",
                    "__2": "דלתא",
                    "__3": "קטלס"
                }
            },
            {
                "value": "",
                "display": "אוסטין",
                "models": {
                    "__0": "מאסטרו",
                    "__1": "מונטגרו",
                    "__2": "מיני",
                    "__3": "מטרו"
                }
            },
            {
                "value": "",
                "display": "אופל",
                "models": {
                    "__0": "אדם",
                    "__1": "אומגה",
                    "__2": "אינסיגניה",
                    "__3": "אסטרה",
                    "__4": "אסקונה",
                    "__5": "גרנדלנד X",
                    "__6": "וקטרה",
                    "__7": "זאפירה",
                    "__8": "טיגרה",
                    "__9": "מוקה",
                    "__10": "מוקה X",
                    "__11": "מריבה",
                    "__12": "קדט",
                    "__13": "קורסה",
                    "__14": "קסקדה",
                    "__15": "קרוסלנד X"
                }
            },
            {
                "value": "אינפיניטי",
                "display": "אינפיניטי",
                "models": {
                    "__0": "G37",
                    "__1": "Q30",
                    "__2": "Q50",
                    "__3": "Q60",
                    "__4": "Q70 / M37",
                    "__5": "QX50 / EX37"
                }
            },
            {
                "value": "אלפא רומיאו",
                "display": "אלפא רומיאו",
                "models": {
                    "__0": "145",
                    "__1": "146",
                    "__2": "147",
                    "__3": "156",
                    "__4": "159",
                    "__5": "JTS.159.3.2",
                    "__6": "164",
                    "__7": "166",
                    "__8": "33",
                    "__9": "4C",
                    "__10": "75",
                    "__11": "90",
                    "__12": "GT",
                    "__13": "GTV",
                    "__14": "בררה",
                    "__15": "ג'וליה",
                    "__16": "ג'ולייטה",
                    "__17": "מיטו MITO",
                    "__18": "סוד",
                    "__19": "ספיידר",
                    "__20": "ספרינט"
                }
            },
            {
                "value": "",
                "display": "אם. ג'י. MG",
                "models": {
                    "__0": "350",
                    "__1": "550",
                    "__2": "MG3",
                    "__3": "RX5",
                    "__4": "ZS"
                }
            },
            {
                "value": "",
                "display": "אסטון מרטין",
                "models": {
                    "__0": "DB11",
                    "__1": "DB9",
                    "__2": "ואנקוויש",
                    "__3": "ונטאג'",
                    "__4": "ראפיד"
                }
            },
            {
                "value": "",
                "display": "ב.מ.וו",
                "models": {
                    "__0": "M1",
                    "__1": "M2",
                    "__2": "M3",
                    "__3": "M4",
                    "__4": "M5",
                    "__5": "M6",
                    "__6": "X1",
                    "__7": "Z3",
                    "__8": "Z4",
                    "__9": "i3",
                    "__10": "i8",
                    "__11": "סדרה 1",
                    "__12": "סדרה 2",
                    "__13": "סדרה 3 קופה / קבריולט",
                    "__14": "סדרה 4",
                    "__15": "סדרה 5",
                    "__16": "סדרה 6",
                    "__17": "סדרה 7",
                    "__18": "סדרה 8"
                }
            },
            {
                "value": "",
                "display": "ביואיק",
                "models": {
                    "__0": "לה סייבר",
                    "__1": "לה קרוס",
                    "__2": "לוצרן",
                    "__3": "סנצ'ורי",
                    "__4": "פארק אבניו",
                    "__5": "ריביירה",
                    "__6": "ריגל"
                }
            },
            {
                "value": "",
                "display": "בנטלי",
                "models": {
                    "__0": "פליינג ספור",
                    "__1": "קונטיננטל GT"
                }
            },
            {
                "value": "",
                "display": "ג'יאו Geo",
                "models": {
                    "__0": "פריזם"
                }
            },
            {
                "value": "",
                "display": "גי'פ Jeep",
                "models": {
                    "__0": "רנגייד"
                }
            },
            {
                "value": "",
                "display": "גרייט וול",
                "models": {
                    "__0": "C10",
                    "__1": "Cool Bear",
                    "__2": "Florid"
                }
            },
            {
                "value": "",
                "display": "דאצ'יה",
                "models": {
                    "__0": "לוגאן MCV",
                    "__1": "לודג'י",
                    "__2": "סנדרו",
                    "__3": "סנדרו Stepway"
                }
            },
            {
                "value": "",
                "display": "דודג'",
                "models": {
                    "__0": "אוונג'ר",
                    "__1": "אספן",
                    "__2": "אריס",
                    "__3": "ג'רני",
                    "__4": "גראנד קרוואן",
                    "__5": "דארט",
                    "__6": "וייפר",
                    "__7": "ולי",
                    "__8": "פלימות'",
                    "__9": "צ'ארג'ר",
                    "__10": "צ'לנג'ר",
                    "__11": "קאליבר"
                }
            },
            {
                "value": "",
                "display": "די.אס DS",
                "models": {
                    "__0": "DS3",
                    "__1": "DS3 קבריולט",
                    "__2": "DS5"
                }
            },
            {
                "value": "",
                "display": "דייהו",
                "models": {
                    "__0": "אספרו",
                    "__1": "טאקומה",
                    "__2": "לאנוס",
                    "__3": "לגנזה",
                    "__4": "נובירה",
                    "__5": "נקסייה",
                    "__6": "סופר סלון",
                    "__7": "סופר רייסר",
                    "__8": "פרינס",
                    "__9": "צ'רמן",
                    "__10": "רייסר"
                }
            },
            {
                "value": "",
                "display": "דייהטסו",
                "models": {
                    "__0": "YRV",
                    "__1": "אפלאוז",
                    "__2": "גרנדמוב",
                    "__3": "מטריה",
                    "__4": "סיריון",
                    "__5": "קורה",
                    "__6": "שרייד",
                    "__7": "שרמנט"
                }
            },
            {
                "value": "",
                "display": "הונדה",
                "models": {
                    "__0": "CR-Z",
                    "__1": "FR-V",
                    "__2": "S 2000",
                    "__3": "אודיסיי",
                    "__4": "אינסייט",
                    "__5": "אקורד",
                    "__6": "ג'אז",
                    "__7": "ג'אז הייבריד",
                    "__8": "לג'נד",
                    "__9": "סטרים",
                    "__10": "סיוויק Type-R",
                    "__11": "סיוויק האצ'בק",
                    "__12": "סיוויק הייבריד",
                    "__13": "סיוויק סדאן",
                    "__14": "סיוויק סטיישן",
                    "__15": "סיוויק קופה",
                    "__16": "פרלוד",
                    "__17": "שאטל"
                }
            },
            {
                "value": "",
                "display": "וולוו",
                "models": {
                    "__0": "240",
                    "__1": "244",
                    "__2": "245",
                    "__3": "264",
                    "__4": "340/345",
                    "__5": "740/744",
                    "__6": "745",
                    "__7": "760",
                    "__8": "850",
                    "__9": "854",
                    "__10": "855",
                    "__11": "940",
                    "__12": "944",
                    "__13": "945",
                    "__14": "960",
                    "__15": "C30",
                    "__16": "C70",
                    "__17": "S40",
                    "__18": "S60",
                    "__19": "S70",
                    "__20": "S80",
                    "__21": "S90",
                    "__22": "V40",
                    "__23": "V50",
                    "__24": "V60",
                    "__25": "V70",
                    "__26": "V90",
                    "__27": "XC70",
                    "__28": "",
                    "__29": "",
                    "__30": ""
                }
            },
            {
                "value": "",
                "display": "טויוטה",
                "models": {
                    "__0": "C-HR",
                    "__1": "GT86",
                    "__2": "IQ",
                    "__3": "MR2",
                    "__4": "אוולון הייבריד",
                    "__5": "אוונסיס",
                    "__6": "אוונסיס ורסו",
                    "__7": "אוריס",
                    "__8": "אוריס הייבריד",
                    "__9": "אייגו",
                    "__10": "ורסו",
                    "__11": "יאריס",
                    "__12": "יאריס הייבריד",
                    "__13": "לנד קרוזר LC100",
                    "__14": "סולרה קבריולט",
                    "__15": "סטארלט",
                    "__16": "סיינה",
                    "__17": "סליקה",
                    "__18": "ספייס ורסו",
                    "__19": "פריוויה",
                    "__20": "פריוס C הייבריד",
                    "__21": "פריוס הייבריד",
                    "__22": "פריוס פלוס הייבריד",
                    "__23": "קאמרי",
                    "__24": "קורולה",
                    "__25": "קורולה RUN-X",
                    "__26": "קורולה הייבריד",
                    "__27": "קרינה"
                }
            },
            {
                "value": "",
                "display": "יגואר",
                "models": {
                    "__0": "F-Type",
                    "__1": "S-Type",
                    "__2": "X-Type",
                    "__3": "XE",
                    "__4": "XF",
                    "__5": "XJ",
                    "__6": "XJ6",
                    "__7": "XJ8",
                    "__8": "XJR",
                    "__9": "XK",
                    "__10": "XK8",
                    "__11": "XKR",
                    "__12": "דימלר",
                    "__13": "סוברין"
                }
            },
            {
                "value": "",
                "display": "יונדאי",
                "models": {
                    "__0": "i10",
                    "__1": "i20",
                    "__2": "i20 קרוס",
                    "__3": "i25",
                    "__4": "i30",
                    "__5": "i30CW",
                    "__6": "I30n",
                    "__7": "i35",
                    "__8": "i40",
                    "__9": "iX20",
                    "__10": "אטוס",
                    "__11": "איוניק",
                    "__12": "אלנטרה",
                    "__13": "אקסנט",
                    "__14": "ג'נסיס",
                    "__15": "ג'נסיס G90",
                    "__16": "גטס",
                    "__17": "ולוסטר",
                    "__18": "טרג'ט",
                    "__19": "לנטרה",
                    "__20": "מטריקס",
                    "__21": "סונטה",
                    "__22": "סונטה הייבריד",
                    "__23": "קופה"
                }
            },
            {
                "value": "",
                "display": "לאדה",
                "models": {
                    "__0": "סמרה"
                }
            },
            {
                "value": "",
                "display": "לינקולן",
                "models": {
                    "__0": "LS",
                    "__1": "MKS",
                    "__2": "MKZ"
                }
            },
            {
                "value": "",
                "display": "ליצ'י",
                "models": {
                    "__0": "A01"
                }
            },
            {
                "value": "",
                "display": "למבורגיני",
                "models": {
                    "__0": "הורקן"
                }
            },
            {
                "value": "",
                "display": "לנצ'יה",
                "models": {
                    "__0": "אפסילון",
                    "__1": "בטה",
                    "__2": "דדרה",
                    "__3": "דלתא",
                    "__4": "פריזמה",
                    "__5": "קאפה",
                    "__6": "תמה"
                }
            },
            {
                "value": "",
                "display": "לקסוס",
                "models": {
                    "__0": "CS",
                    "__1": "CT200H",
                    "__2": "ES300H",
                    "__3": "GS250",
                    "__4": "GS300",
                    "__5": "GS300H",
                    "__6": "GS450H",
                    "__7": "IS250",
                    "__8": "IS300h",
                    "__9": "LC500",
                    "__10": "LS400",
                    "__11": "LS430",
                    "__12": "LS460",
                    "__13": "LS500",
                    "__14": "LS600HL",
                    "__15": "NX",
                    "__16": "RC",
                    "__17": "SC430"
                }
            },
            {
                "value": "",
                "display": "מאזדה",
                "models": {
                    "__0": "121",
                    "__1": "2",
                    "__2": "2 דמיו",
                    "__3": "3",
                    "__4": "323",
                    "__5": "323 לאנטיס",
                    "__6": "5",
                    "__7": "6",
                    "__8": "626",
                    "__9": "MPV",
                    "__10": "MX-3",
                    "__11": "MX-5",
                    "__12": "MX-6",
                    "__13": "RX-8",
                    "__14": "דמיו",
                    "__15": "פרמסי",
                    "__16": "קסדוס"
                }
            },
            {
                "value": "",
                "display": "מזראטי",
                "models": {
                    "__0": "גיבלי",
                    "__1": "גראן-טוריסמו",
                    "__2": "גראן-קבריו",
                    "__3": "קוואטרופורטה"
                }
            },
            {
                "value": "",
                "display": "מיני",
                "models": {
                    "__0": "JCW",
                    "__1": "ONE",
                    "__2": "פייסמן",
                    "__3": "קאנטרימן",
                    "__4": "קופה",
                    "__5": "קופר",
                    "__6": "קופר קבריולט",
                    "__7": "קלאבן",
                    "__8": "רודסטר"
                }
            },
            {
                "value": "",
                "display": "מיצובישי",
                "models": {
                    "__0": "ASX",
                    "__1": "GT3000",
                    "__2": "I-MIEV",
                    "__3": "אאוטלנדר",
                    "__4": "אטראז'",
                    "__5": "אקליפס",
                    "__6": "גאלאנט",
                    "__7": "גרנדיס",
                    "__8": "כריזמה",
                    "__9": "לנסר",
                    "__10": "לנסר איוולושן",
                    "__11": "לנסר ספורטבק",
                    "__12": "לנסר קלאסיק",
                    "__13": "סופר לנסר",
                    "__14": "סיגמא",
                    "__15": "ספייס וואגן",
                    "__16": "ספייס סטאר",
                    "__17": "ספייס ראנר",
                    "__18": "צ'אמפ",
                    "__19": "קולט"
                }
            },
            {
                "value": "",
                "display": "מרצדס",
                "models": {
                    "__0": "A-Class",
                    "__1": "B-Class",
                    "__2": "B-Class Taxi",
                    "__3": "C-Class",
                    "__4": "C-Class Taxi",
                    "__5": "C-Class קופה",
                    "__6": "CE",
                    "__7": "CL",
                    "__8": "CLA",
                    "__9": "CLC",
                    "__10": "CLK",
                    "__11": "CLS",
                    "__12": "E-Class",
                    "__13": "E-Class Taxi",
                    "__14": "E-Class קופה / קבריולט",
                    "__15": "GLA",
                    "__16": "GT AMG",
                    "__17": "R-Class",
                    "__18": "S-Class",
                    "__19": "S-Class קבריולט",
                    "__20": "S-Class קופה",
                    "__21": "SE",
                    "__22": "SL",
                    "__23": "SLC",
                    "__24": "SLK",
                    "__25": "SLS",
                    "__26": "V-Class"
                }
            },
            {
                "value": "",
                "display": "ניסאן",
                "models": {
                    "__0": "370Z",
                    "__1": "GT-R",
                    "__2": "SX 200",
                    "__3": "Z350",
                    "__4": "אלטימה",
                    "__5": "אלמרה",
                    "__6": "ג'וק Juke",
                    "__7": "טידה",
                    "__8": "ליף",
                    "__9": "מיקרה",
                    "__10": "מקסימה",
                    "__11": "נוט NOTE",
                    "__12": "סאני",
                    "__13": "סנטרה",
                    "__14": "פולסר Pulsar",
                    "__15": "פרימרה",
                    "__16": "קווסט",
                    "__17": "קשקאי",
                    "__18": "קשקאי פלוס 2"
                }
            },
            {
                "value": "",
                "display": "ננג'ינג",
                "models": {
                    "__0": "סיטי ספיריט"
                }
            },
            {
                "value": "",
                "display": "סאאב",
                "models": {
                    "__0": "9-3.",
                    "__1": "9-5.",
                    "__2": "900",
                    "__3": "9000",
                    "__4": "קונברטיבל"
                }
            },
            {
                "value": "",
                "display": "סאנגיונג",
                "models": {
                    "__0": "רודיוס"
                }
            },
            {
                "value": "",
                "display": "סובארו",
                "models": {
                    "__0": "B3 האצ'בק",
                    "__1": "B3 סדאן",
                    "__2": "B4",
                    "__3": "BRZ",
                    "__4": "GLF",
                    "__5": "SVX",
                    "__6": "XV",
                    "__7": "אאוטבק",
                    "__8": "אימפרזה WRX STI",
                    "__9": "אימפרזה גרנד לאונה",
                    "__10": "אימפרזה טורבו",
                    "__11": "אימפרזה ניו אייג'",
                    "__12": "ג'סטי",
                    "__13": "לאונה",
                    "__14": "לבורג",
                    "__15": "לגאסי",
                    "__16": "רקס"
                }
            },
            {
                "value": "",
                "display": "סוזוקי",
                "models": {
                    "__0": "SX4",
                    "__1": "SX4 קרוסאובר",
                    "__2": "איגניס",
                    "__3": "אלטו",
                    "__4": "בלנו",
                    "__5": "וואגון",
                    "__6": "ליאנה",
                    "__7": "מרוטי",
                    "__8": "סוויפט",
                    "__9": "סופר בלנו",
                    "__10": "סלריו",
                    "__11": "ספלאש"
                }
            },
            {
                "value": "",
                "display": "סיאט",
                "models": {
                    "__0": "איביזה",
                    "__1": "אלהמברה",
                    "__2": "אלתיאה",
                    "__3": "אלתיאה XL",
                    "__4": "ארונה",
                    "__5": "טולדו",
                    "__6": "לאון",
                    "__7": "לאון קופרה",
                    "__8": "מי MII",
                    "__9": "מלגה",
                    "__10": "מרבלה",
                    "__11": "קורדובה",
                    "__12": "רונדה"
                }
            },
            {
                "value": "",
                "display": "סיטרואן",
                "models": {
                    "__0": "AX",
                    "__1": "BX",
                    "__2": "C אליזה",
                    "__3": "C-ZERO",
                    "__4": "C1",
                    "__5": "C15",
                    "__6": "C2",
                    "__7": "C3",
                    "__8": "C3 איירקרוס",
                    "__9": "C3 פיקאסו",
                    "__10": "C4",
                    "__11": "C4 ספייסטורר",
                    "__12": "C4 פיקאסו",
                    "__13": "C4 קקטוס",
                    "__14": "C5",
                    "__15": "C6",
                    "__16": "C8",
                    "__17": "CX",
                    "__18": "DS3",
                    "__19": "DS3 קבריולט",
                    "__20": "DS4",
                    "__21": "DS5",
                    "__22": "XM",
                    "__23": "ZX",
                    "__24": "ויזה",
                    "__25": "סקסו",
                    "__26": "קסנטיה",
                    "__27": "קסרה",
                    "__28": "קסרה פיקאסו"
                }
            },
            {
                "value": "",
                "display": "סמארט",
                "models": {
                    "__0": "בראבוס",
                    "__1": "פולס",
                    "__2": "פורטו",
                    "__3": "פורפור",
                    "__4": "פיור",
                    "__5": "פשיין",
                    "__6": "רודסטר"
                }
            },
            {
                "value": "",
                "display": "סקודה",
                "models": {
                    "__0": "אוקטביה",
                    "__1": "אוקטביה RS",
                    "__2": "אוקטבה ספייס",
                    "__3": "ייטי Yeti",
                    "__4": "ניו פליסיה",
                    "__5": "סופרב",
                    "__6": "סיטיגו Citigo",
                    "__7": "סקאלה",
                    "__8": "פאביה",
                    "__9": "פאביה ספייס",
                    "__10": "פורמן",
                    "__11": "פיבוריט",
                    "__12": "פליסיה",
                    "__13": "ראפיד",
                    "__14": "ראפיד ספייסבק",
                    "__15": "רומסטר"
                }
            },
            {
                "value": "",
                "display": "פולקסווגן",
                "models": {
                    "__0": "CADDY KOMBI",
                    "__1": "איוס",
                    "__2": "אפ UP",
                    "__3": "בורה",
                    "__4": "ג'טה",
                    "__5": "גולף",
                    "__6": "גולף GTI",
                    "__7": "גולף R",
                    "__8": "גולף ספורטוואן",
                    "__9": "גולף פלוס",
                    "__10": "דרבי",
                    "__11": "ונטו",
                    "__12": "חיפושית",
                    "__13": "חיפושית החדשה",
                    "__14": "טוראן",
                    "__15": "פאסאט",
                    "__16": "פאסאט CC",
                    "__17": "פולו",
                    "__18": "פולו GTI",
                    "__19": "פולו קלאסיק",
                    "__20": "פייטון",
                    "__21": "קוראדו",
                    "__22": "שירוקו"
                }
            },
            {
                "value": "",
                "display": "פונטיאק",
                "models": {
                    "__0": "G-6",
                    "__1": "LE 6000",
                    "__2": "בונוביל",
                    "__3": "גרנד אם",
                    "__4": "גרנד פרי",
                    "__5": "טמפסט",
                    "__6": "טרנס אם",
                    "__7": "סולסטיס",
                    "__8": "סנברד",
                    "__9": "פיירבירד"
                }
            },
            {
                "value": "",
                "display": "פורד",
                "models": {
                    "__0": "GT",
                    "__1": "S-מקס",
                    "__2": "tourer",
                    "__3": "אוריון",
                    "__4": "אסקורט",
                    "__5": "ברנדה",
                    "__6": "גלאקסי",
                    "__7": "וינדסטאר",
                    "__8": "טאורוס",
                    "__9": "טורר",
                    "__10": "טנדרבירד",
                    "__11": "טרייסר",
                    "__12": "לינקולן- MKC",
                    "__13": "לינקולן- MKS",
                    "__14": "לינקולן- MKZ",
                    "__15": "מונדאו",
                    "__16": "מוסטנג",
                    "__17": "מרקורי",
                    "__18": "סיירה",
                    "__19": "סקורפיו",
                    "__20": "פוקוס",
                    "__21": "פיאסטה",
                    "__22": "פיוז'ן",
                    "__23": "קונקט",
                    "__24": "קורטינה"
                }
            },
            {
                "value": "",
                "display": "פורשה",
                "models": {
                    "__0": "718 בוקסטר",
                    "__1": "718 קאיימן",
                    "__2": "GT2 RS 911",
                    "__3": "GT3 911",
                    "__4": "GTS 911",
                    "__5": "911 טארגה",
                    "__6": "911 טורבו",
                    "__7": "911 קאררה",
                    "__8": "פאנאמרה"
                }
            },
            {
                "value": "",
                "display": "פיאט",
                "models": {
                    "__0": "127",
                    "__1": "131",
                    "__2": "132",
                    "__3": "500",
                    "__4": "500C",
                    "__5": "500L",
                    "__6": "500X",
                    "__7": "ABARTH אברת'",
                    "__8": "אונו",
                    "__9": "אונו סיליקטה",
                    "__10": "אלבאה",
                    "__11": "בראבה",
                    "__12": "בראבו",
                    "__13": "דונה",
                    "__14": "טיפו",
                    "__15": "טמפרה",
                    "__16": "כרומה",
                    "__17": "לינאה",
                    "__18": "מולטיפלה",
                    "__19": "מריאה",
                    "__20": "סדיצ'י",
                    "__21": "סקודו",
                    "__22": "פונטו",
                    "__23": "פונטו איבו",
                    "__24": "פונטו גרנדה",
                    "__25": "פונטו ספייס",
                    "__26": "פנדה",
                    "__27": "קובו QUBO",
                    "__28": "קופה",
                    "__29": "רגאטה",
                    "__30": "ריטמו"
                }
            },
            {
                "value": "",
                "display": "פיג'ו",
                "models": {
                    "__0": "106",
                    "__1": "107",
                    "__2": "108",
                    "__3": "2008",
                    "__4": "204",
                    "__5": "205",
                    "__6": "206",
                    "__7": "206 +",
                    "__8": "206CC",
                    "__9": "207",
                    "__10": "208",
                    "__11": "GTI 208",
                    "__12": "3008",
                    "__13": "301",
                    "__14": "305",
                    "__15": "306",
                    "__16": "307",
                    "__17": "308",
                    "__18": "308CC",
                    "__19": "309",
                    "__20": "405",
                    "__21": "406",
                    "__22": "407",
                    "__23": "5008",
                    "__24": "504",
                    "__25": "505",
                    "__26": "508",
                    "__27": "605",
                    "__28": "607",
                    "__29": "RCZ",
                    "__30": "I0n"
                }
            },
            {
                "value": "",
                "display": "פרארי",
                "models": {
                    "__0": "458",
                    "__1": "488 GTB",
                    "__2": "488 ספיידר",
                    "__3": "488 פיאסטה",
                    "__4": "575 מרנלו",
                    "__5": "812 סופרפאסט",
                    "__6": "F12 ברלינטה",
                    "__7": "FF",
                    "__8": "GTC4Lusso",
                    "__9": "פורטופינו",
                    "__10": "קליפורניה",
                    "__11": "STS",
                    "__12": "סוויל",
                    "__13": "דוויל"
                }
            },
            {
                "value": "",
                "display": "קאדילק",
                "models": {
                    "__0": "ATS",
                    "__1": "CT6",
                    "__2": "CTS",
                    "__3": "XLR",
                    "__4": "XTS"
                }
            },
            {
                "value": "",
                "display": "קיה",
                "models": {
                    "__0": "KT900",
                    "__1": "אופטימה Optima",
                    "__2": "אופירוס",
                    "__3": "ג'ויס",
                    "__4": "לאו",
                    "__5": "מג'נטיס",
                    "__6": "מנטור",
                    "__7": "נירו EV",
                    "__8": "נירו הייבריד",
                    "__9": "סול",
                    "__10": "סטוניק",
                    "__11": "סטינגר",
                    "__12": "סיד CEED",
                    "__13": "ספיה",
                    "__14": "סראטו",
                    "__15": "פורטה",
                    "__16": "פיקנטו",
                    "__17": "פרייד",
                    "__18": "קארנס",
                    "__19": "קדנזה",
                    "__20": "קלארוס",
                    "__21": "קריאטו",
                    "__22": "קרניבל",
                    "__23": "ריו",
                    "__24": "שומה"
                }
            },
            {
                "value": "",
                "display": "קרייזלר",
                "models": {
                    "__0": "200",
                    "__1": "300C",
                    "__2": "300C SRT",
                    "__3": "300M",
                    "__4": "PT קרוזר",
                    "__5": "אמפריאל",
                    "__6": "גראנד וויאג'ר",
                    "__7": "ויזן",
                    "__8": "טאון קאנטרי",
                    "__9": "נאון",
                    "__10": "סברינג",
                    "__11": "סטרטוס",
                    "__12": "סרטוגה",
                    "__13": "פסיפיקה",
                    "__14": "קאליבר",
                    "__15": "קרוספייר"
                }
            },
            {
                "value": "",
                "display": "רובר",
                "models": {
                    "__0": "214",
                    "__1": "216",
                    "__2": "414",
                    "__3": "416",
                    "__4": "420",
                    "__5": "600",
                    "__6": "620",
                    "__7": "623",
                    "__8": "825",
                    "__9": "827",
                    "__10": "MG VVC",
                    "__11": "MG ZR",
                    "__12": "MG ZS",
                    "__13": "MG ZT",
                    "__14": "MGF",
                    "__15": "R25",
                    "__16": "R45",
                    "__17": "R75"
                }
            },
            {
                "value": "",
                "display": "רנו",
                "models": {
                    "__0": "11",
                    "__1": "18",
                    "__2": "19",
                    "__3": "21",
                    "__4": "25",
                    "__5": "4",
                    "__6": "5",
                    "__7": "9",
                    "__8": "אספאט",
                    "__9": "גרנד סניק",
                    "__10": "וול סאטיס",
                    "__11": "זואי",
                    "__12": "טווינגו",
                    "__13": "טליה",
                    "__14": "לגונה",
                    "__15": "לוגאן MCV",
                    "__16": "לטיטוד",
                    "__17": "מגאן אסטייט / גראנד טור",
                    "__18": "מגאן גראנד קופה",
                    "__19": "מגאן",
                    "__20": "מגאן קופה ספורט",
                    "__21": "סימבול",
                    "__22": "סנדרו",
                    "__23": "סניק",
                    "__24": "ספרן",
                    "__25": "פלואנס",
                    "__26": "פלואנס חשמלי",
                    "__27": "קליאו B",
                    "__28": "קליאו אסטייט",
                    "__29": "קליאו",
                    "__30": "קפצ'ור"
                }
            },
            {
                "value": "",
                "display": "שברולט",
                "models": {
                    "__0": "אבאו",
                    "__1": "אופטרה",
                    "__2": "אורלנדו",
                    "__3": "אימפלה",
                    "__4": "אלרו",
                    "__5": "אפיקה",
                    "__6": "אפלנדר",
                    "__7": "אקווינוקס",
                    "__8": "ברטה",
                    "__9": "וולט",
                    "__10": "ויואנט",
                    "__11": "טראקס",
                    "__12": "לומינה",
                    "__13": "מאליבו",
                    "__14": "מונטה קרלו",
                    "__15": "סוניק",
                    "__16": "סיליבריטי",
                    "__17": "ספארק",
                    "__18": "קאמרו",
                    "__19": "קוואליר",
                    "__20": "קורבט",
                    "__21": "קורבט Z06",
                    "__22": "קורסיקה",
                    "__23": "קפריס",
                    "__24": "קרוז"
                }
            }
        ]
    }
}

