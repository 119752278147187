//const env = 'pr   oduction' || process.env.TECHNOTEST_ENV || 'develop';
const env = process.env.NODE_ENV || 'local';
let config = {};
// App Details
config.appName = 'Technotest';
config.server = {
    restPrefix: 'rest',
    assetPrefix: 'public/images'
};
switch (env) {
    case 'local':
        config.isProduction = false;
        config.server.host = process.env.HOST || 'http://127.0.0.1';
        config.server.port = 8060;
        break;
    case 'local.docker':
        config.isProduction = false;
        config.server.host = process.env.HOST || 'http://127.0.0.1';
        config.server.port = 8061;
        break;
    case 'development':
        config.isProduction = false;
        config.server.host = process.env.HOST || 'http://127.0.0.1';
        config.server.port = 8060;
        break;
    case 'production':
        config.isProduction = true;
        config.server.host = process.env.HOST || 'https://api.technotest.org';
        config.server.port = 443;
        break;
    default:
        config.isProduction = false;
        config.server.host = process.env.HOST || 'http://127.0.0.1';
        config.server.port = process.env.PORT || 8060;
        break;
}
config.server.url = `${config.server.host}:${config.server.port}`;

console.log(env);
console.log(config);

export default config;
