import React, {useState} from 'react'
import {connect} from "react-redux";
import {Field, reduxForm, formValueSelector, FieldArray} from 'redux-form'

import './personalForm.scss';

import CustomSelect from "../../InputFields/Select";
import ChildFields from "../../InputFields/ChildFields";

import NewCarForm from "./NewCarForm";
import StyleButton from '../../StyleButton';

import xIcon from "../../../icons/x-icon.svg";
import addCarIcon from "../../../icons/cars/icon-car-add.svg";
// Validators
import {
    required,
    checkCars,
    yesOrNoRequired,
    isAlpha,
    isPhoneNumber,
    normalizePhone, normalizeCar
} from '../../../services/validators'
import {selectFormat, selectParse} from "../../../services/utils";
import InputField from "../../InputFields/InputField";
import Popup from "reactjs-popup";


const Cars = ({fields, meta: {touched, error}, isEdit, label}) => {
    const [showModal, setShowModal] = useState(false);
    const [editedCar, setEditedCar] = useState(null);

    const editCar = (car) => {
        return; // TODO: finish edit car flow
        setEditedCar(car);
        setShowModal(true);
    }

    const saveCar = (form) => {
        if (!editedCar) {
            fields.push(form);
        } else {

        }
        setShowModal(false);
    }

    return (
        <div className={"cars"}>
            <div className={"label"}>{label}</div>
            <div className={"cars-container"}>
                {fields.getAll().map((car, index) => (
                    <div className={"license-plate"} key={index}>
                        <div className={"number"} onClick={isEdit ? () => {editCar(car)} : null}>{normalizeCar(car.number)}</div>
                        {isEdit && <button className={"delete-car"} title={"מחק רכב"} onClick={() => {fields.remove(index)}}>
                            <img src={xIcon} alt="" />
                        </button>}
                    </div>
                ))}
                {error && <div className="input-error-message">{error}</div>}
                {isEdit && <StyleButton label='רכב חדש'
                                        callback={() => setShowModal(true)}
                                        color="#0067a5"
                                        isDisabled={false}
                                        isOutlined={true}
                                        customStyle={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            alignSelf: 'flex-end',
                                            fontSize: "16rem",
                                            border: "solid 1px #0067a5",
                                            padding: "4rem 12rem",
                                            width: "120rem",
                                            margin: "10rem"
                                        }}
                                        icon={addCarIcon}
                />}
            </div>
            {<Popup open={showModal} className={"new-car-modal"} onClose={() => setShowModal(false)} closeOnDocumentClick={true}>
                <>
                    <img src={addCarIcon} style={{height: "80rem", marginBottom: "20rem"}}/>
                    <NewCarForm onSubmit={saveCar} initialValues={editedCar} />
                </>
            </Popup>}
        </div>
    )
}


class ProfileForm extends React.Component {
    render() {
        const {user, handleSubmit, error, invalid, isEdit, cancelEdit, dispatch} = this.props;
        return (
            <form className="form profileForm" onSubmit={handleSubmit}>
                <div className="text-line">
                    <Field name={`fullname`}
                           type="text"
                           component={InputField}
                           validate={[isAlpha, required]}
                           label="שם מלא:"
                           placeholder="שם מלא*"
                           disabled={!isEdit}
                    />
                    <Field name={`phone`}
                           type="text"
                           component={InputField}
                           validate={[required,isPhoneNumber]}
                           label="טלפון:"
                           placeholder="טלפון*"
                           normalize={normalizePhone}
                           format={normalizePhone}
                           disabled={!isEdit}
                        // parse={(value, name) => value ? value.split('-').join('') : value}
                    />
                </div>

                {user.cars.length &&
                <FieldArray name="cars"
                            label="רכבים בבעלותך:"
                            component={Cars}
                            isEdit={isEdit}
                            validate={[checkCars]}
                />}
                {error && <div className="form-error">{error}</div>}
                <StyleButton label={isEdit ? 'שמירה' : 'עריכה'}
                             type="submit"
                             color={"#0067a5"}
                             isDisabled={isEdit && invalid}
                             customStyle={{
                                 margin: "35rem auto 0",
                                 fontSize: "22rem",
                                 minWidth: "200rem"
                             }}
                />
                {isEdit && <StyleButton label={'ביטול'}
                                        isOutlined={true}
                                        callback={() => cancelEdit(dispatch)}
                                        color={'#0067a5'}
                                        customStyle={{
                                            margin: "5rem auto 0",
                                            fontSize: "22rem",
                                            minWidth: "200rem",
                                            borderWidth: "1px"
                                        }}
                />}
            </form>
        )
            ;
    }
}

const ProfileFormRedux = reduxForm({
    form: 'profile-form',
    enableReinitialize: true,
})(ProfileForm);
const selector = formValueSelector('profile-form');// <-- same as form name
const ProfileFormDecorated = connect((state, props) => {
    // const hasCars = selector(state, 'hasChildren');
    let {_id, fullname, phone, cars} = state.user;
    return {
        user: state.user,
        // isEdit: false,
        // hasChildrenValue: hasChildrenValue,
        // maritalStatusEnum: state.app.data.maritalStatus,
        initialValues: {_id, fullname, phone, cars}
    };
})(ProfileFormRedux);
export default ProfileFormDecorated;
