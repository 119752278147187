import React from 'react';
import DeliveryTruck from '../icons/cars/delivery-truck.svg';
import OffRoad from '../icons/cars/off-road.svg';
import Sedan from '../icons/cars/sedan.svg';
import Van from '../icons/cars/van.svg';
import '../styles/carsBar.scss';

const cars = [
    DeliveryTruck,
    OffRoad,
    Sedan,
    Van
];
const CarsBar = () => {
    return (
        <div className='carsBar'>
            {cars.map((car, index) => {
                return <img key={index} className="car" src={car} alt=""/>
            })}
        </div>
    )
};

export default CarsBar;