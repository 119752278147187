import action from '../action.js'
import types from "./types";
import Http from "../../services/http";
import Storage from "../../services/storage";

/**
 * Save Login Credentials to sessionStorage
 */
async function saveCredentialsToStorage(user) {
    await sessionStorage.setItem('@technotest:api/user', JSON.stringify(user));
}

export function getUser() {
    return action(async dispatch => {
        const token = await Storage.get('token');
        if (token) {
            let user = await Http.get('users/current', {token});
            dispatch({type: types.GET_USER, payload: user});
            Http.setAuthorizationHeader(token);
            return user;
        } else {
            return null;
        }
    })
}

export function register(payload) {
    return action(async dispatch => {
        let user = await Http.post('users/register', payload);
        dispatch({type: types.SET_USER, payload: user});
        return user;
    })
}

export function sendVerificationCode(phone) {
    return action(async dispatch => {
        let response = await Http.post(`users/sendVerificationCode`, {phone});
        //dispatch({type: types.SET_USER, payload: response});
        return response;
    })
}

export function login(phone, verificationCode) {
    return action(async dispatch => {
        let user = await Http.post(`users/authenticate`, {phone, verificationCode});
        await Storage.set("token", user.token);
        await saveCredentialsToStorage(user);
        Http.setAuthorizationHeader(user.token);
        dispatch({type: types.SET_USER, payload: user});
        return user;
    })
}

/**
 * Logout
 */
export function logout(callServer = true) {
    return action(async dispatch => {
        if (callServer) {
            await Http.get('logout');
        }
        await removeCredentialsFromStorage();
        await Storage.remove('token');
        dispatch({type: types.RESET})
    })
}

/**
 * Remove Login Credentials from sessionStorage
 */
async function removeCredentialsFromStorage() {
    await sessionStorage.removeItem('@technotest:api/user');
}

export function resetPassword(token, password) {
    return action(async dispatch => {
        let user = await Http.post(`reset/${token}`, {password});
        await saveCredentialsToStorage(user);
        dispatch({type: types.SET_USER, payload: user});
        return user;
    })
}

export function update(userObj, saveToServer = true, showLoader = true) {
    return action(async dispatch => {
        if (saveToServer) {
            let updatedUser = await Http.put(`users/${userObj._id}`, userObj);
            // await saveCredentialsToStorage(updatedUser.results);
            // dispatch({type: types.SET_USER, payload: updatedUser.results})
            debugger;
            let user = await Http.get(`users/${userObj._id}`);
            dispatch({type: types.GET_USER, payload: user});
            // await saveCredentialsToStorage(userObj);
            // dispatch({type: types.SET_USER, payload: userObj})
        } else {
            dispatch({type: types.SET_USER, payload: userObj})
        }
    }, showLoader)
}
