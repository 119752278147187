import types from './types'
// Set initial state
const initialState = {
    userAppointments: [],
    availableAppointments: {}
};

export function appointmentsReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_APPOINTMENTS:
            return {
                ...state,
                userAppointments: action.payload
            };
        case types.SET_AVAILABLE_APPOINTMENTS:
            return {
                ...state,
                availableAppointments: action.payload
            };
        case types.CREATE_APPOINTMENT:
            return {
                ...state,
                userAppointments: [...state.userAppointments, action.payload]
            };
        default :
            return state;
    }
}
