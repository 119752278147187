import React from 'react';
import goBackIcon from '../icons/rigth-arrow-icon.svg';
import '../styles/wizard.scss';
import swooshIcon from '../icons/swoosh-icon.svg';

const itemsTable = [
    {
        id: 1,
        section: "שם פרטי ומשפחה",
    }, {
        id: 2,
        section: "תאריך לידה",
    }, {
        id: 3,
        section: "מצב משפחתי",
    }, {
        id: 4,
        section: "מקצוע",
    }, {
        id: 5,
        section: "תחביבים מסוכנים",
    }, {
        id: 6,
        section: "פרטים אישיים",
    }, {
        id: 7,
        section: "סיום והוספת בן-זוג",
    },
];

function Wizard({currentPage, onClick, goBack, stages}) {
    return (
        <div className="wizard">
            <ProgressBar current={currentPage} onClick={onClick} stages={stages}/>
        </div>
    );
}

const ProgressBar = ({current, onClick, stages}) => {

    return (
        <div className="progress-bar">
            <div className={"progress-bar-text bold"}>
                {"שלב " + (current+1)}
            </div>
            <div className={"progress-bar-text"}>
                {"מתוך " + stages.length}
            </div>
        </div>);
};



const BarItem = ({id, label, current, onClick}) => {
    let myClass = "status ";
    myClass += id < current ? "after" : "";
    myClass += id === current ? "current" : "";
    myClass += id > current ? "before" : "";
    let active = id <= current;
    return (
        <div className="bar-item"
             onClick={active ? () => onClick(id - 1) : null}
             style={{cursor: active ? "pointer" : "default"}}>
            <div className={myClass}>
                {id >= current ? id : <img src={swooshIcon} alt="" className="icon"/>}
            </div>
            <div className="label">
                {label}
            </div>
        </div>
    );
};
export default Wizard;