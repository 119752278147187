import React from 'react';
import LayoutGuest from "../../components/LayoutGuest";
import LoginForm from "../../components/Forms/LoginForm";
import './loginScreen.scss';
import {SubmissionError} from "redux-form";
import {connect} from 'react-redux';
// Actions
import {getUser, login, sendVerificationCode} from '../../store/user/actions';
import SubHeader from "../../components/SubHeader";
import CarsBar from "../../components/CarsBar";
import VerificationForm from "../../components/Forms/VerificationForm";
import {getUserAppointments} from "../../store/appointments/actions";
import {getData} from "../../store/app/actions";

class LoginScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sentVerificationCode: false
        }
    }

    handleLogin = async (formValues) => {
        try {
            const {phone} = formValues;
            let response = await this.props.sendVerificationCode(phone);
            this.setState({phone, sentVerificationCode: true})
        } catch (err) {
            console.error(err);
            throw new SubmissionError({
                _error: err
            });
        }
    };
    handleVerification = async (formValues) => {
        try {
            const {phone} = this.state;
            const {login, history, getUserAppointments, getData} = this.props;
            const {verificationCode} = formValues;
            let user = await login(phone, verificationCode);
            if (user.status === 'active') {
                await getUserAppointments();
                await getData();
                history.replace('/services');
            } else {
                history.replace(`/signup`);
            }
        } catch (err) {
            console.error(err);
            throw new SubmissionError({
                _error: err
            });
        }
    };

    render() {
        const {sentVerificationCode} = this.state;
        return (
            <LayoutGuest>
                <SubHeader/>
                <div className="loginWrapper">
                    <div className="formHolder">
                        <CarsBar/>
                        <div className="formWrapper">
                            {sentVerificationCode ?
                                <VerificationForm onSubmit={this.handleVerification}/>
                                : <LoginForm onSubmit={this.handleLogin}/>
                            }
                        </div>
                    </div>
                </div>
            </LayoutGuest>
        );
    }
}

// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({});
// Any actions to map to the component?
const mapDispatchToProps = {
    getData,
    getUser,
    getUserAppointments,
    login,
    sendVerificationCode,
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
