import React from 'react';
import '../styles/subHeader.scss';
const SubHeader = () => {
    return(
        <div className={"bottom-titles-container"}>
            <div className={"bottom-first-row"}>
                <div className={"bottom-title-red"}>
                    {"טכנו"}
                </div>
                <div className={"bottom-title-blue"}>
                    {"טסט"}
                </div>
                <div className={"bottom-title"}>
                    {"רשת מכוני רישוי ובדיקת"}
                </div>
            </div>
            <div className={"bottom-title"}>
                {"רכב לפני קניה בפריסה ארצית!"}
            </div>
        </div>
    )
}

export default SubHeader;