import React from 'react'
import {Field, reduxForm} from 'redux-form'
import InputField from '../InputFields/InputField'
import StyleButton from '../StyleButton';
import '../../styles/form.scss';
// Validators
import {isPhoneNumber, normalizePhone, required} from '../../services/validators'

let LoginForm = props => {
    const {handleSubmit, error, invalid} = props;
    return (
        <form className="form" onSubmit={handleSubmit}>
            <div className="heading">
                אנא הכנס מספר טלפון לצורך אימות
            </div>
            <div className='inputs-wrapper'>
                <Field name={`phone`}
                       type="text"
                       component={InputField}
                       validate={[required, isPhoneNumber]}
                       placeholder="טלפון*"
                       normalize={normalizePhone}
                />
                {error && <div className="form-error">{error}</div>}
                <StyleButton label='שלח קוד אימות'
                             type="submit"
                             isDisabled={invalid}
                             customStyle={{margin: "40rem 0"}}
                />
            </div>
        </form>
    )
};
export default LoginForm = reduxForm({
    form: 'login',
    initialValues: {
        phone: '',
    }
})(LoginForm);
