import { Component } from 'react';
import { withRouter } from 'react-router-dom';

const InitialDataStatus = {
    NOT_LOADED: 'NOT_LOADED',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    LOADING_FAILED: 'LOADING_FAILED',
};

class AppBoot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialData: InitialDataStatus.NOT_LOADED
        }
        // ReactGA.initialize('UA-143933671-1', {
        //     debug: false
        // });
    }

    async componentDidMount() {
        try {
            this.setState({initialData: InitialDataStatus.LOADING});
            this.props.toggleLoader(true);
            await this.getInitialData();
            this.props.toggleLoader(false);
            this.setState({initialData: InitialDataStatus.LOADED})
        } catch (err) {
            console.error(err);
            console.error('Failed to load initial data from server');
            this.setState({initialData: InitialDataStatus.LOADING_FAILED})
        }
    }

    async getInitialData() {
        const {history, getData, getEnums, getUser, getUserAppointments} = this.props;
        const enums = await getEnums();
        // const data = await getData();
        try {
            let user = await getUser();
            if (user) {
                const data = await getData();
                await getUserAppointments();
            }
            console.log("Registered user, redirect to homepage")
            // if (user.status === 'created') {
            //     history.replace(`/signup`);
            // }
        }
        catch (e) {
            console.log("Unknown user")
        }
    };

    render() {
        return this.state.initialData === InitialDataStatus.LOADED && this.props.children;
    }
}

export default withRouter(AppBoot)
