import {combineReducers} from 'redux';

import {reducer as form} from 'redux-form';
import {appReducer as app} from './app/reducer';
import {userReducer as user} from './user/reducer';
import {appointmentsReducer as appointments} from './appointments/reducer';
import {standReducer as stand} from './stand/reducer';

// Combine all reducers
const appReducer = combineReducers({
    form,
    app,
    user,
    appointments,
    stand
});
export default appReducer;
