import React, {useState} from 'react'
import {change, Field, reduxForm} from 'redux-form'
import InputField from '../../InputFields/InputField'
import StyleButton from '../../StyleButton';
import '../../../styles/form.scss';

// Validators
import {email, required, checkAppointmentHour, normalizeCar} from '../../../services/validators'
import {dateParse, selectFormat, selectParse} from "../../../services/utils";
import CustomSelect from "../../InputFields/Select";
import {getPlacesAutocomplete} from "../../../services/geo";
import Autocomplete from "../../InputFields/Autocomplete";
import DatePicker from "../../InputFields/DatePicker";
import dropDownButton from "../../../icons/down-arrow-icon.svg"

import checkBeforePurchaseForm from "./checkBeforePurchaseForm.scss"
import {connect} from "react-redux";

const asyncValidate = async (formValues, dispatch, props) => {
    const response = await props.getAvailableAppointments({date: formValues.date, branch: formValues.branch.value, service: props.service._id});
    const error = checkAppointmentHour(formValues.date, response.includeTimes);
    if (error) {
        throw {date: error}
    }
}

const getAvailableHours = async (type, data, formValues, service, getAvailableAppointments, setAvailableAppointments) => {
    const branch = type === 'branch' ? data : formValues.branch;
    const date = type === 'date' ? data : formValues ? formValues.date : new Date();
    const response = await getAvailableAppointments({date: date || new Date(), branch: branch.value, service: service._id});
    setAvailableAppointments(response);
}

let CheckBeforePurchaseForm = (props) => {
    const {handleSubmit, error, invalid, updateField, goBack, service, branches, user, formValues, getAvailableAppointments, availableHours = {}} = props;
    const userCars = user.cars.map(car => ({label: normalizeCar(car.number), value: car._id}));
    const [availableAppointments, setAvailableAppointments] = useState(availableHours);


    return (
        <form className="form membershipForm checkBeforePurchaseForm" onSubmit={handleSubmit}>

            <div className={"full-width"}>
                <Field
                    name="branch"
                    placeholder="מכון הבדיקה בו יסופק השירות"
                    label=""
                    options={branches}
                    onChange={(value) => getAvailableHours('branch', value, formValues, service, getAvailableAppointments, setAvailableAppointments)}
                    onCustomChange={() => updateField('checkBeforePurchaseForm', 'date', null)}
                    // format={selectFormat(branches)}
                    // parse={selectParse}
                    component={CustomSelect}
                    validate={[required]}
                />
            </div>

            <Field name={`date`}
                   component={DatePicker}
                   disabled={!formValues || !formValues.branch}
                   minDate={new Date()}
                   timeParams={availableAppointments}
                   onCalendarClose={(ref) => {console.log(ref); debugger;}}
                   asyncValidate={() => asyncValidate({...formValues}, null, {getAvailableAppointments, service})}
                   onChange={(value) => getAvailableHours('date', value, formValues, service, getAvailableAppointments, setAvailableAppointments)}
                   validate={[required]}
                   label=""
                   placeholder="מועד שירות"
                   props={{showTimeSelect: true}}
            />
            <Field name="car"
                // type="tel"
                // component={InputField}
                // normalize={normalizeCar}
                   options={userCars}
                   format={selectFormat(userCars)}
                   parse={selectParse}
                   component={CustomSelect}
                   validate={[required]}
                   label=""
                   placeholder="מס׳ הרכב"
            />
            {/*<StyleButton label='רכב חדש'*/}
            {/*             callback={() => null}*/}
            {/*             color="#0067a5"*/}
            {/*             isDisabled={false}*/}
            {/*             isOutlined={true}*/}
            {/*             customStyle={{*/}
            {/*                 margin: "10rem 0",*/}
            {/*                 alignSelf: 'flex-end',*/}
            {/*                 fontSize: "16rem",*/}
            {/*                 border: "solid 1px #0067a5",*/}
            {/*                 width: "134px"*/}
            {/*             }}*/}
            {/*/>*/}
            {/*<Field name={`group`}*/}
            {/*       options={[]}*/}
            {/*       format={selectFormat([])}*/}
            {/*       parse={selectParse}*/}
            {/*       component={CustomSelect}*/}
            {/*       validate={[]}*/}
            {/*       placeholder="קבוצת הרכב"*/}
            {/*       label="2x4"*/}
            {/*       props={{style: {dropdownIndicator: <img src={dropDownButton}/>}}}*/}
            {/*/>*/}
            {/*<Field name={`2x4`}*/}
            {/*       options={[]}*/}
            {/*       format={selectFormat([])}*/}
            {/*       parse={selectParse}*/}
            {/*       component={CustomSelect}*/}
            {/*       validate={[]}*/}
            {/*       label="2x4"*/}
            {/*       placeholder="גלגלים"*/}
            {/*/>*/}
            {/*<Field name={`fuelType`}*/}
            {/*       options={[]}*/}
            {/*       format={selectFormat([])}*/}
            {/*       parse={selectParse}*/}
            {/*       component={CustomSelect}*/}
            {/*       validate={[]}*/}
            {/*       placeholder="מנוע"*/}
            {/*       label="2x4"*/}

            {/*/>*/}

            {error && <div className="form-error">{error}</div>}
            <div className={"buttons-container"}>
                <StyleButton label={props.disableGoBack ? 'סגור' : 'חזור'}
                             color="#0067a5"
                    // isDisabled={props.disableGoBack}
                             isOutlined={true}
                             callback={goBack}
                             customStyle={{
                                 margin: "10rem 5rem",
                                 alignSelf: 'flex-end',
                                 fontSize: "22rem",
                                 borderWidth: "1px",
                                 minWidth: "45%"
                             }}
                />
                <StyleButton label='המשך'
                             onClick={handleSubmit}
                             isDisabled={false}
                             customStyle={{
                                 margin: "10rem 5rem",
                                 alignSelf: 'flex-end',
                                 fontSize: "22rem",
                                 backgroundColor: "#0067a5",
                                 minWidth: "45%"
                             }}
                />
            </div>
        </form>
    )
};

const mapDispatchToProps = dispatch => ({
    updateField: (form, field, newValue) => dispatch(change(form, field, newValue))
});


const CheckBeforePurchaseFormRedux = reduxForm({
    form: 'checkBeforePurchaseForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    // enableReinitialize: true,
    asyncValidate,
    asyncChangeFields: ['date']
})(CheckBeforePurchaseForm);


const CheckBeforePurchaseFormDecorated = connect((state, props) => {
    return {
        formValues: state.form.checkBeforePurchaseForm ? state.form.checkBeforePurchaseForm.values : {},
        availableHours: state.appointments.availableAppointments,
        user: state.user,
        branches: state.app.data.branches.map(branch => ({label: branch.displayName, value: branch._id})),
        // initialValues: props.initialValues,
        // initialValues: {date: null},
    };
}, mapDispatchToProps)(CheckBeforePurchaseFormRedux);

export default CheckBeforePurchaseFormDecorated;
