import action from '../action.js'
import types from "./types";
import Http from "../../services/http";
import {getAvailableAppointmentsFromServer} from "../../services/appointment";
import {reset} from "redux-form";

export function createAppointment({appointmentDetails}) {
    return action(async dispatch => {
        let response = await Http.post(`appointments/create`, appointmentDetails);
        dispatch({type: types.CREATE_APPOINTMENT, payload: response});
        dispatch(reset('serviceForm'));
        dispatch(reset('paymentForm'));
    }, true)
}

export function getUserAppointments() {
    return action(async dispatch => {
        let appointments = await Http.get(`appointments/myAppointments`);
        dispatch({type: types.SET_APPOINTMENTS, payload: appointments});
        return appointments;
    })
}

export function getAvailableAppointments(data) {
    return action(async dispatch => {
        let appointments = await getAvailableAppointmentsFromServer(data);
        dispatch({type: types.SET_AVAILABLE_APPOINTMENTS, payload: appointments});
        return appointments;
    })
}
