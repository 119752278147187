import React from 'react'
import '../styles/terms.scss';

const title = "תנאי שימוש ותקנון של מועדון הלקוחות של טכנו טסט";

const content = "1.    כללי\n" +
    "1.1    תנאי השימוש שלהלן חלים על השימוש באתר www.azorim.co.il או כל אתר אחר שהוקם/יוקם ע\"י אזורים, לבדה או ביחד עם אחרים, שאין לו תקנון ספציפי (להלן: \"האתר\"). למען הסר ספק, המונח \"האתר\" מתייחס לאתר על כל חלקיו ולתוכן האתר מכל סוג ומין שהוא, לרבות כל המידע והתוכן הכלול באתר, בין אם הוא גלוי למשתמשים ובין אם לאו. כמו כן, המונח \"האתר\" יתייחס גם לחלקים של האתר אשר הגישה אליהם נעשית באמצעות כתובות אחרות ושמות מתחם דומים. \n" +
    "1.2    השימוש באתר מעיד על הסכמתך לתנאי השימוש, כלשונם ובמלואם, ומהווה הסכמה בהתנהגות לתנאי השימוש. המשתמש מתבקש לקרוא את תנאי השימוש בקפידה. \n" +
    "1.3    האתר מנוהל ומופעל על-ידי אזורים בנין חברה להשקעות בפיתוח ובבנין בע\"מ ועל ידי חברות בת ושותפויות קשורות (להלן, ביחד: \"אזורים\").\n" +
    "1.4    הגישה לאתר והשימוש בו ובכלל זה בתכנים הכלולים בו ובשירותים השונים הפועלים בו כפופים לתנאים המפורטים להלן (להלן: \"תנאי השימוש\") המסדירים את היחסים בין אזורים לבין כל גולש/ת, צופה, משתמש/ת באתר או במידע המצוי בו ו/או מקבל/ת מידע ו/או שירות המפורסם באתר, במישרין או בעקיפין (להלן: \"המשתמש/ת\" או \"המשתמשים\").\n" +
    "1.5    תנאי השימוש חלים על השימוש באתר ובתכנים הכלולים בו באמצעות כל מחשב או מכשיר תקשורת אחר (כדוגמת טלפון סלולארי, סמארטפון, טאבלט וכיו\"ב). כמו כן תנאי השימוש חלים על השימוש באתר בין באמצעות רשת האינטרנט ובין באמצעות כל רשת או אמצעי תקשורת אחרים.\n" +
    "1.6    בתנאי השימוש המונחים \"מידע\" או \"תוכן\" או \"תכנים\" כוללים מידע מכל מין וסוג, לרבות כל תוכן מילולי, חזותי, קולי, אור קולי (audio visual) או כל שילוב ביניהם וכן עיצוב, עיבוד, עריכה, הפצה ודרך הצגתם של התכנים לרבות (אך לא רק): תמונה, צילום, איור, הנפשה, תרשים, הדמיה, סרטון, קובץ קולי וכן כל אובייקט, תוכנה, קובץ, קוד מחשב, יישום, תסדיר (format), פרוטוקול, טופס אלקטרוני, מאגר נתונים וממשק וכל תו, סימן סמל וצלמית (icon).\n" +
    "1.7    כל התייחסות לחלק מן האתר מתייחסת לכל חלק מסוים מתוכן האתר, לרבות כל 1.    כללי\n" +
    "1.1    תנאי השימוש שלהלן חלים על השימוש באתר www.azorim.co.il או כל אתר אחר שהוקם/יוקם ע\"י אזורים, לבדה או ביחד עם אחרים, שאין לו תקנון ספציפי (להלן: \"האתר\"). למען הסר ספק, המונח \"האתר\" מתייחס לאתר על כל חלקיו ולתוכן האתר מכל סוג ומין שהוא, לרבות כל המידע והתוכן הכלול באתר, בין אם הוא גלוי למשתמשים ובין אם לאו. כמו כן, המונח \"האתר\" יתייחס גם לחלקים של האתר אשר הגישה אליהם נעשית באמצעות כתובות אחרות ושמות מתחם דומים. \n" +
    "1.2    השימוש באתר מעיד על הסכמתך לתנאי השימוש, כלשונם ובמלואם, ומהווה הסכמה בהתנהגות לתנאי השימוש. המשתמש מתבקש לקרוא את תנאי השימוש בקפידה. \n" +
    "1.3    האתר מנוהל ומופעל על-ידי אזורים בנין חברה להשקעות בפיתוח ובבנין בע\"מ ועל ידי חברות בת ושותפויות קשורות (להלן, ביחד: \"אזורים\").\n" +
    "1.4    הגישה לאתר והשימוש בו ובכלל זה בתכנים הכלולים בו ובשירותים השונים הפועלים בו כפופים לתנאים המפורטים להלן (להלן: \"תנאי השימוש\") המסדירים את היחסים בין אזורים לבין כל גולש/ת, צופה, משתמש/ת באתר או במידע המצוי בו ו/או מקבל/ת מידע ו/או שירות המפורסם באתר, במישרין או בעקיפין (להלן: \"המשתמש/ת\" או \"המשתמשים\").\n" +
    "1.5    תנאי השימוש חלים על השימוש באתר ובתכנים הכלולים בו באמצעות כל מחשב או מכשיר תקשורת אחר (כדוגמת טלפון סלולארי, סמארטפון, טאבלט וכיו\"ב). כמו כן תנאי השימוש חלים על השימוש באתר בין באמצעות רשת האינטרנט ובין באמצעות כל רשת או אמצעי תקשורת אחרים.\n" +
    "1.6    בתנאי השימוש המונחים \"מידע\" או \"תוכן\" או \"תכנים\" כוללים מידע מכל מין וסוג, לרבות כל תוכן מילולי, חזותי, קולי, אור קולי (audio visual) או כל שילוב ביניהם וכן עיצוב, עיבוד, עריכה, הפצה ודרך הצגתם של התכנים לרבות (אך לא רק): תמונה, צילום, איור, הנפשה, תרשים, הדמיה, סרטון, קובץ קולי וכן כל אובייקט, תוכנה, קובץ, קוד מחשב, יישום, תסדיר (format), פרוטוקול, טופס אלקטרוני, מאגר נתונים וממשק וכל תו, סימן סמל וצלמית (icon).\n" +
    "1.7    כל התייחסות לחלק מן האתר מתייחסת לכל חלק מסוים מתוכן האתר, לרבות כל "

const Terms = () => {
    return (
        <div className="terms">
            <div className="title">{title}</div>
            <div className="content">{content}</div>
        </div>
    )
}

export default Terms