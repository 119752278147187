import React, {Component} from 'react';
import '../Services/servicesScreen.scss'
import moment from "moment";
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'
import styles from "./historyScreen.scss"
import testIcon from "../../../icons/cars/test.svg"
import accidentIcon from "../../../icons/cars/accident.svg"
import buyIcon from "../../../icons/cars/buy.svg"
import frontIcon from "../../../icons/cars/car-front.svg"
import isMobile from "ismobilejs";
import {createAppointment} from "../../../store/appointments/actions";
import {connect} from "react-redux";

const columns = [
    {
        Header: 'סוג השירות',
        accessor: 'service'
    },
    {
        Header: 'מועד השירות',
        accessor: 'appointmentDate',
    },
    {
        Header: 'המכון המספק',
        accessor: 'branch',
    },
    {
        Header: 'עלות השירות',
        accessor: 'cost',
    },
    {
        Header: 'הערות ופרטים נוספים',
        accessor: 'notes',
    },

]

const services = {
    test: {
        label: 'טסט',
        icon: testIcon
    },
    front: {
        label: 'כיוון פרונט',
        icon: frontIcon
    },
    buy: {
        label: 'בדיקה לפני קניה',
        icon: buyIcon
    },
    accident: {
        label: 'תאונה',
        icon: accidentIcon
    },

}

const mockHistory = [
    {
        // service: <img src={testIcon}/>,
        service: "test",
        appointmentDate: moment().format("DD.MM.YY"),
        branch: "תל אביב - דרום",
        car: "32-334-93",
        cost: 1200,
        comments: "הערכות לגבי השירות, פרטים נוספים ודגשים על הטיפול"
    },
    {
        service: "accident",
        appointmentDate: moment().format("DD.MM.YY"),
        branch: "תל אביב - דרום",
        car: "32-334-93",
        cost: 1200,
        comments: "הערכות לגבי השירות, פרטים נוספים ודגשים על הטיפול"
    },
    {
        service: "buy",
        appointmentDate: moment().format("DD.MM.YY"),
        branch: "תל אביב - דרום",
        car: "32-334-93",
        cost: 1200,
        comments: "הערכות לגבי השירות, פרטים נוספים ודגשים על הטיפול"
    },
    {
        service: "front",
        appointmentDate: moment().format("DD.MM.YY"),
        branch: "תל אביב - דרום",
        car: "32-334-93",
        cost: 1200,
        comments: "הערכות לגבי השירות, פרטים נוספים ודגשים על הטיפול"
    },
    {
        service: "accident",
        appointmentDate: moment().format("DD.MM.YY"),
        branch: "תל אביב - דרום",
        car: "32-334-93",
        cost: 1200,
        comments: "הערכות לגבי השירות, פרטים נוספים ודגשים על הטיפול"
    }
]

const icons = {
    test: testIcon,
    buy: buyIcon,
    front: frontIcon,
    accident: accidentIcon,
}

const ServiceIcon = ({service}) => {
    // service = services[service];
    return (
      <div className={"service-icon-wrapper"}>
          <img className={"icon"} src={icons[service.name]} alt={service.label}/>
          <div className={"label"}>{service.label}</div>
      </div>
    )
}

const MobileTable = ({history}) => {
    if (!history) {
        return null;
    }

    const getField = (label, value) => (
      <div className={"field-wrapper"}>
        <div className={"label"}>{label}</div>
        <div className={"value"}>{value}</div>
      </div>
    )

    return (
      <div className={"mobileTable"}>
          {history.map((service, index) => {
              return (
                <div key={index} className={"service-wrapper"}>
                    {service.service}
                    <div className={"details-wrapper"}>
                        <div className={"section"}>
                            {getField('תאריך', service.appointmentDate)}
                            {getField('מכון', service.branch)}
                        </div>
                        <div className={"section"}>
                            {getField('מספר רכב', service.car)}
                            {getField('מחיר', service.cost)}
                        </div>
                        <div className={"section"}>
                            {getField('הערות ופרטים נוספים', service.notes)}
                        </div>
                    </div>
                </div>
              )
          })}
      </div>
    )
}

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    async componentDidMount() {
        this.setState({history:mockHistory});
    }

    getHistory = () => {
        const {history} = this.state;
        const {appointments} = this.props;
        return appointments && appointments.map(appointment => {
            return {
                ...appointment,
                car: appointment.car.number,
                appointmentDate: moment(appointment.appointmentDate).format("HH:mm ,DD/MM/YYYY"),
                branch: appointment.branch.displayName,
                cost: appointment.cost ? "₪" + appointment.cost : "המחיר יקבע בגמר הטיפול",
                service: <ServiceIcon service={appointment.service}/>
            }
        })
    }

    render() {
        return (
          <div className={"history-screen-container"}>
              <div className={"title"}>
                  היסטוריית השירותים שלי
              </div>
              <div className={"history-container"}>
                {isMobile(window.navigator).any ?
                  <MobileTable history={this.getHistory()} /> :
                  <ReactTable
                    columns={columns}
                    data={this.getHistory()}
                    defaultPageSize={5}
                    showPagination={false}
                    resizable={false}
                    sortable={false}
                    multiSort={false}
                    noDataText={"כאן יופיעו כל השירותים שיוזמנו"}
                    column={{
                      // Renderers
                      Cell: undefined,
                      Header: undefined,
                      Footer: undefined,
                      Aggregated: undefined,
                      Pivot: undefined,
                      PivotValue: undefined,
                      Expander: undefined,
                      Filter: undefined,
                      // Standard options
                      sortable: undefined, // use table default
                      resizable: undefined, // use table default
                      filterable: undefined, // use table default
                      show: true,
                      //minWidth: 240,
                      // Cells only
                      className: '',
                      style: {},
                      getProps: () => ({}),
                      // Headers only
                      headerClassName: '',
                      headerStyle: {},
                      getHeaderProps: () => ({}),
                      // Footers only
                      footerClassName: '',
                      footerStyle: {},
                      getFooterProps: () => ({}),
                      filterAll: false,
                      filterMethod: undefined,
                      sortMethod: undefined,
                      defaultSortDesc: undefined,
                    }}
                  />
                }
              </div>
          </div>
        );
    }
}

// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
    user: state.user,
    appointments: state.appointments.userAppointments,
    services: state.app.data.services
});

// Any actions to map to the component?
const mapDispatchToProps = {
    createAppointment
};

export default connect(mapStateToProps, mapDispatchToProps)(History)
