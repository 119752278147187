import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import '../styles/layoutGuest.scss';

class LayoutGuest extends React.Component {
    render() {
        return (
            <div className="layoutUser">
                <Header showSignIn={this.props.showSignIn} showLogo={this.props.showLogo}/>
                {
                    this.props.children
                }
                <Footer/>
            </div>
        )
    }
}

export default LayoutGuest;