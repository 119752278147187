import appTypes from "./app/types";

export default function action(_action, isHttp) {
    return async (dispatch, getState) => {
        try {
            if (isHttp) {
                dispatch({type: appTypes.TOGGLE_LOADER, payload: true});
                const response = await _action(dispatch, getState);
                dispatch({type: appTypes.TOGGLE_LOADER, payload: false});
                return response;
            } else {
                return await _action(dispatch, getState);
            }
        }
        catch (ex) {
            dispatch({type: '@set_error', payload: ex});
            throw ex;
        }
    }
}