import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import '../../styles/checkBox.scss';

import classNames from 'classnames';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';

export default class CheckBoxField extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
        ]),
        label: PropTypes.string,
        defaultChecked: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        color: PropTypes.string,
    };

    static defaultProps = {
        label: '',
        defaultChecked: false,
        disabled: false,
        className: '',
        color: '',
        // onChange: () => {}
    };

    componentDidMount() {
        // const { onChange, defaultChecked, input } = this.props;
        // onChange(defaultChecked);
    }

    render() {
        const {
            disabled, className, name, onChange, label, color,
        input, meta: {touched, error}
        } = this.props;
        const CheckboxClass = classNames({
            'checkbox-btn': true,
            disabled,
        });

        return (
            <label
                className={`${CheckboxClass} ${className ? ` checkbox-btn--${className}` : ''}`}
                htmlFor={name}
            >
                <input
                    {...input}
                    className="checkbox-btn__checkbox"
                    type="checkbox"
                    id={name}
                    name={name}
                    checked={input.value}
                    disabled={disabled}
                />
                <span
                    className="checkbox-btn__checkbox-custom"
                    style={color ? { background: color, borderColor: color } : {}}
                >
          <CheckIcon />
        </span>
                {className === 'button'
                    ? (
                        <span className="checkbox-btn__label-svg">
              <CheckIcon onchange={() => {}} className="checkbox-btn__label-check" />
              <CloseIcon className="checkbox-btn__label-uncheck" />
            </span>
                    ) : ''}
                <span className="checkbox-btn__label">
          {label}
        </span>
            </label>
        );
    }
}
