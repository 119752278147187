import React, {useState} from 'react';
import LogoutButton from "./LogoutButton";
import userIcon from "../icons/user.svg"
import '../styles/headerUser.scss';
import {
    withRouter,
    Link,
} from "react-router-dom";

import logo from '../images/logo.svg';
import {NavLink} from  "react-router-dom"
import isMobile from 'ismobilejs';
import Sidebar from "./Sidebar";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const topBarLinks= [
    {
        label: "השירותים שלנו",
        link: "/services"
    },
    {
        label: "היסטוריית שירותים",
        link: "/history"
    },
    {
        label: "פרופיל אישי",
        link: "/profile",
        hideInDesktop: true
    },

]

const ProfileMenu = ({history, logout}) => {
    const [showDropDown, setShowDropDown] = useState(false);

    const handleLogout = async () => {
        setShowDropDown(false);
        await logout(false);
        history.push("/login")
    }

    return (
      <div>
          <img src={userIcon} className={"topbar-user-icon"} onClick={() => {
              setShowDropDown(!showDropDown)
          }}/>
          {showDropDown &&
          <div className={"dropdown-container"}>
              <div className={"dropdown-item"} onClick={() => {
                  setShowDropDown(false);
                  history.push("/profile")
              }
              }>
                  פרופיל אישי
              </div>
              <div className={"dropdown-item"} onClick={handleLogout}>
                  התנתקות
              </div>
          </div>
          }
      </div>
    )
}

class HeaderUser extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isSidebarOpen: false
        };

        this.unlisten = this.props.history.listen((location, action) => {
            this.toggleSidebar();
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    toggleSidebar = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({isSidebarOpen: !this.state.isSidebarOpen})
    }

    render() {
        const {isSidebarOpen} = this.state;
        return (
          <div className="headerHolder">
              {isMobile(window.navigator).phone ?
                <div className={"topbar-container"}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={this.toggleSidebar}
                      edge="start"
                      disableRipple={true}
                      // className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon style={{ color: '#1a1919', fontSize: 30 }} />
                    </IconButton>
                    <Sidebar isOpen={isSidebarOpen}
                             toggleSidebar={this.toggleSidebar}
                             items={
                                 <div className={"sidebar-links-container"}>
                                     <IconButton
                                       color="inherit"
                                       aria-label="close drawer"
                                       onClick={this.toggleSidebar}
                                       edge="start"
                                       disableRipple={true}
                                       style={{padding: 0, marginBottom: 20}}
                                       // className={clsx(classes.menuButton, open && classes.hide)}
                                     >
                                         <CloseIcon style={{ color: '#54698d', fontSize: 30 }} />
                                     </IconButton>
                                     {topBarLinks.map((link, index) =>
                                       <NavLink
                                         key={index}
                                         onClick={this.toggleSidebar}
                                         className='topbar-link'
                                         activeClassName="active-topbar-link"
                                         to={link.link}>
                                           {link.label}
                                           <div className={"active-topbar-link-underline"}/>
                                       </NavLink>
                                     )}
                                     <div className={"logout-button"} style={{flex: 1}}>התנתקות</div>
                                 </div>} />
                </div> :
                <div className={"topbar-container"}>
                    <div className={"topbar-links-container"}>
                        {topBarLinks.filter(l => !l.hideInDesktop).map((link, index) =>
                          <NavLink
                            key={index}
                            className='topbar-link'
                            activeClassName="active-topbar-link"
                            to={link.link}>
                              {link.label}
                              <div className={"active-topbar-link-underline"}/>
                          </NavLink>
                        )}
                    </div>
                    <ProfileMenu history={this.props.history} logout={this.props.logout} />
                </div>
              }
              <div className={"logo-container"}>
                  <div className={"logo-container-redline"}>
                      <img src={logo} className={"logo"}/>
                  </div>
              </div>

          </div>
        )
    }
}

export default withRouter(HeaderUser);
