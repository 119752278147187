import action from '../action.js'
import types from "./types";
import {getCurrentCarFromStand} from "../../services/stand";



export function getCurrentCar(branchId, standId) {
    return action(async dispatch => {
        let ret = await getCurrentCarFromStand(branchId, standId);
        dispatch({type: types.GET_CURRENT_CAR, payload: ret});
        return ret;
    })
}
