import React, {Component} from 'react';
import {reset} from "redux-form";
import {connect} from "react-redux";
import './profileScreen.scss'

import userEditIcon from "../../../icons/user-edit.svg"
import userIcon from "../../../icons/user.svg"
import moment from "moment";
import StyleButton from "../../../components/StyleButton";
import ProfileForm from "../../../components/Forms/Personal/ProfileForm";
import {createNewCar} from "../../../services/car";
import {update} from "../../../store/user/actions";

const mockUser =
    {
        firstName: "ישראל",
        lastName: "ישראלי",
        address: "טשרניחובסקי 2, תל אביב-יפו",
        ownedCars: [
            {
                licensePlate: "21-232-12"
            },
            {
                licensePlate: "22-562-12"
            },
            {
                licensePlate: "21-232-12"
            },
            {
                licensePlate: "22-562-18"
            },
            {
                licensePlate: "41-456-15"
            },
        ]
    }

const userFields = [
    {
        label: 'שם פרטי',
        accessor: 'firstName'
    },
    {
        label: 'שם משפחה',
        accessor: 'lastName'
    },
    {
        label: 'כתובת',
        accessor: 'address'
    },
    {
        label: 'רכבים בבעלותך',
        accessor: 'ownedCars',
        type: 'cars'
    }
]

const Cars = ({userCars}) => (
    <div className={"cars-container"}>
        {userCars.map((car, index) => (
            <div className={"license-plate"} key={index}>
                {car.licensePlate}
            </div>
        ))}
    </div>
)

class Profile extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isEdit: false
        };
    }

    async componentDidMount () {
    }

    handleSubmit = async (form, dispatch) => {
        let {isEdit} = this.state;
        if (isEdit) {
            debugger;
            let userCars = form.cars.filter(car => car._id).map(c => c._id);
            const newCars = form.cars.filter(car => !car._id).map(c => (
                {
                    number: c.number.split('-').join(''),
                    maker: c.maker.label,
                    model: c.model.label,
                    mileage: c.mileage,
                    // testDate
                }
            ))
            await Promise.all(newCars.map(async car => {
                let newCar = await createNewCar(car);
                userCars.push(newCar._id);
                return Promise.resolve();
            }));
            form.cars = userCars;
            await this.props.updateUser(form);
        }
        this.setState({isEdit: !isEdit})
    }

    cancelEdit = (dispatch) => {
        this.setState({isEdit: false});
        dispatch(reset('profile-form'));
    }

    render () {
        let {isEdit} = this.state;
        return (
            <div className={"profile-screen-container"}>
                <div className={"title"}>הפרופיל האישי שלך</div>
                <div className={"sub-title"}>{isEdit ? "ערוך את הפרטיים האישיים שלך" : ""}</div>
                <div className={"details-container"}>
                    <img src={isEdit ? userEditIcon : userIcon} style={{height: "60rem", marginBottom: "40rem"}}/>
                    <ProfileForm onSubmit={this.handleSubmit.bind(this)}
                                 cancelEdit={this.cancelEdit} isEdit={this.state.isEdit}/>
                    {/*<div className={"text-line"}>
            {userFields.map((field, index) => (
              <div className={"field-wrapper"} key={index}>
                <div className={"label"}>{field.label}:</div>
                {field.type === 'cars' ?
                  <Cars userCars={mockUser.ownedCars} /> :
                  <div className={"value"}>{mockUser[field.accessor]}</div>}
              </div>
            ))}
            <StyleButton label='עריכה'
                         onClick={()=>null}
                         isDisabled={false}
                         customStyle={{
                           margin: "35rem auto 0",
                           fontSize: "22rem",
                           backgroundColor: "#0067a5",
                           minWidth: "206px"
                         }}
            />
          </div>*/}
                </div>
            </div>
        );
    }
}

// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
    user: state.user,
    services: state.app.data.services
});

// Any actions to map to the component?
const mapDispatchToProps = {
    updateUser: update
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
