import React from 'react'
import Select, {components} from 'react-select';
import downArrow from '../../icons/down-arrow-icon.svg';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={downArrow} alt="" className="arrow"/>
        </components.DropdownIndicator>
    );
};

const CustomSelect = ({name, value, defaultValue, onCustomChange = () => {}, noOptionsMessage = () => 'לא נמצאו תוצאות',
                          isMulti, isDisabled, input, options, label, placeholder = "",
                          labelIcon, required, width = "100rem", containerClass = '', meta: {touched, error}}) => {
    const errorClass = touched && error ? 'error-color' : '';
    const containerClasses = ['input-group', containerClass, errorClass];
    const inputStyle = {
        fontSize: '16rem',
        letterSpacing: '0.37rem',
        color: '#0067a5',
        padding: '0',
        textAlign: 'start'
    };

    const customStyles = {
        valueContainer: (provided) => ({
            ...provided,
            padding: 0,
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            padding: 0,
            margin: '5rem 0 0 0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            visibility: 'hidden',
        }),
        singleValue: (provided) => ({
            ...provided,
            ...inputStyle,
        }),
        option: (provided, state) => ({
            ...provided,
            ...inputStyle,
            padding: '10rem',
            color: state.isSelected ? 'white' : '#5b5a5f',
        }),
        input: (provided) => ({
            ...inputStyle,
            fontSize: '16rem'
        }),
        placeholder: (provided) => ({
            ...provided,
            ...inputStyle,
            fontSize: '16rem'
        }),
        control: (provided) => ({
            display: 'flex',
            flexDirection: 'row',
            minWidth: width,
            height: '20rem',
            alignItems: 'center',
            padding: '4rem 10rem'
            // borderBottom: '1rem solid #49a044',
            //cursor: 'pointer'
        }),
    };
    const customTheme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            text: "#0067a5",
            primary: "#0067a5",
            primary25: "#f1f2f3",
            primary50: "#cccccc",
            natural10: "#0067a5",
        }
    });
    return (
        <div className={containerClasses.join(' ')}>
            <div className="label-input">
                <div className="label-wrapper">
                    {labelIcon && <img src={labelIcon} alt=""/>}
                    <label htmlFor={input.name}>{required ? '*' : ''}{label}</label>
                </div>
                <div className="input-wrapper">
                    <Select name={input.name}
                            errorState={touched && error}
                            value={input.value}
                        // defaultValue={defaultValue}
                            isDisabled={isDisabled}
                            onChange={(value) => {
                                input.onChange(value);
                                onCustomChange(value)
                            }}
                            options={options}
                            isMulti={isMulti}
                            components={{DropdownIndicator}}
                            placeholder={placeholder}
                            menuPlacement="auto"
                            noOptionsMessage={noOptionsMessage}
                            styles={customStyles}
                            theme={customTheme}/>
                </div>
            </div>
            {touched && error && <div className='input-error-message'>{error}
            </div>}
        </div>
    )
};
export default CustomSelect;
