import React from 'react'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import InputField from '../../InputFields/InputField'
import StyleButton from '../../StyleButton';
import '../../../styles/form.scss';
// Validators
import {email, required, passwordValidator, yesOrNoRequired} from '../../../services/validators'
import {dateParse, selectFormat, selectParse} from "../../../services/utils";
import CustomSelect from "../../InputFields/Select";
import {getCitiesAutocomplete} from "../../../services/geo";
import Autocomplete from "../../InputFields/Autocomplete";
import DatePicker from "../../InputFields/DatePicker";

import paymentForm from "./paymentForm.scss"
import moment from "moment";
import CustomRadio from "../../InputFields/Radio";
import {connect} from "react-redux";

const getMonths = () => {
    let months = []
    for (let i = 1; i <= 12; i++) {
        months.push({value: i, label: i})
    }
    return months;
}

const getYears = () => {
    let years = [];
    let currentYear = moment().format("YYYY");
    for (let i = 0; i < 11; i++) {
        years.push({value: i, label: moment(currentYear).add(i, "year").format("YYYY")})
    }
    return years;
}

const yesOrNo = [{label: 'תשלום אונליין', value: true}, {label: 'תשלום במכון', value: false}];


let AccidentForm = props => {
    const {handleSubmit, error, invalid, goBack} = props;

    return (
        <form className="form membershipForm paymentForm" style={{alignSelf: 'center'}} onSubmit={handleSubmit}>
            <div className={"fields-container"}>
                <div className={"form-header"}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{margin: "10rem 0"}}>
                            {"מוקד תאונות: 03-333-3333"}
                        </div>
                        <div style={{fontWeight: "bold", margin: "10rem 0"}}>
                            {"מענה מידי, שירות מקצועי ואמין"}
                        </div>
                    </div>
                </div>
            </div>

            {error && <div className="form-error">{error}</div>}
            <div className={"buttons-container"} style={{justifyContent: 'center'}}>
                <StyleButton label='סגור'
                             color="#0067a5"
                             // isDisabled={props.disableGoBack}
                             isOutlined={true}
                             callback={goBack}
                             customStyle={{
                                 marginTop: "50rem",
                                 marginBottom: "10rem",
                                 alignSelf: 'flex-end',
                                 fontSize: "22rem",
                                 borderWidth: "1px",
                                 minWidth: "45%"
                             }}
                />
            </div>
        </form>
    )
};

const selector = formValueSelector('AccidentForm');// <-- same as form name

const AccidentFormRedux = reduxForm({
    form: 'AccidentForm',
    // enableReinitialize: true,
})(AccidentForm);


const AccidentFormDecorated = connect((state) => {
    // const isOnlineAccident = selector(state, `isOnlineAccident`);
    return {
        // isOnlineAccidentValue: isOnlineAccident,
        // initialValues: {isOnlineAccident: true},

    };
})(AccidentFormRedux);
export default AccidentFormDecorated;
