import React from 'react'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import InputField from '../../InputFields/InputField'
import StyleButton from '../../StyleButton';
import '../../../styles/form.scss';
// Validators
import {email, required, passwordValidator, yesOrNoRequired} from '../../../services/validators'
import {dateParse, selectFormat, selectParse} from "../../../services/utils";
import CustomSelect from "../../InputFields/Select";
import {getCitiesAutocomplete} from "../../../services/geo";
import Autocomplete from "../../InputFields/Autocomplete";
import DatePicker from "../../InputFields/DatePicker";

import paymentForm from "./paymentForm.scss"
import moment from "moment";
import CustomRadio from "../../InputFields/Radio";
import {connect} from "react-redux";

const getMonths = () => {
    let months = []
    for (let i = 1; i <= 12; i++) {
        months.push({value: i, label: i})
    }
    return months;
}

const getYears = () => {
    let years = [];
    let currentYear = moment().format("YYYY");
    for (let i = 0; i < 11; i++) {
        years.push({value: i, label: moment(currentYear).add(i, "year").format("YYYY")})
    }
    return years;
}

const yesOrNo = [{label: 'תשלום אונליין', value: true}, {label: 'תשלום במכון', value: false}];


let PaymentForm = props => {
    const {handleSubmit, error, invalid, goBack, isOnlinePayment, service} = props;
    const years = getYears();
    const months = getMonths();
    return (
        <form className="form membershipForm paymentForm" onSubmit={handleSubmit}>
            {/*<Field name={`isOnlinePayment`}*/}
            {/*       options={yesOrNo}*/}
            {/*       component={CustomRadio}*/}
            {/*       validate={[yesOrNoRequired]}*/}
            {/*       label=""*/}
            {/*/>*/}
            {/*{isOnlinePayment &&*/}
            {service.allowOnlinePayment &&
            <div className={"fields-container"}>
                <Field name="CCNumber"
                       type="tel"
                       component={InputField}
                       validate={[required]}
                       label=""
                       placeholder="מס׳ כרטיס"
                />
                <Field name={`CCExpiryYear`}
                       options={years}
                       format={selectFormat(years)}
                       parse={selectParse}
                       component={CustomSelect}
                       validate={[]}
                       label="תוקף"
                       placeholder="שנת פג תוקף"

                />
                <Field name={`CCExpiryMonth`}
                       options={months}
                       format={selectFormat(months)}
                       parse={selectParse}
                       component={CustomSelect}
                       validate={[]}
                       label="תוקף"
                       placeholder="חודש פג תוקף"
                />
                <Field name="CVV"
                       type="tel"
                       component={InputField}
                       validate={[required]}
                       label=""
                       placeholder="CVV"
                />
                <Field name="cardHolderName"
                       type="tel"
                       component={InputField}
                       validate={[required]}
                       label=""
                       placeholder="שם בעל הכרטיס"
                />
                <Field name="cardHolderID"
                       type="tel"
                       component={InputField}
                       validate={[required]}
                       label=""
                       placeholder="ת.ז בעל הכרטיס"
                />
            </div>
            }

            {error && <div className="form-error">{error}</div>}
            <div className={"buttons-container"}>
                <StyleButton label='חזור'
                             color="#0067a5"
                             isDisabled={props.disableGoBack}
                             isOutlined={true}
                             callback={goBack}
                             customStyle={{
                                 margin: "10rem 5rem",
                                 alignSelf: 'flex-end',
                                 fontSize: "22rem",
                                 borderWidth: "1px",
                                 minWidth: "45%"
                             }}
                />
                <StyleButton label='אישור'
                             type="submit"
                             isDisabled={false}
                             customStyle={{
                                 margin: "10rem 5rem",
                                 alignSelf: 'flex-end',
                                 fontSize: "22rem",
                                 backgroundColor: "#0067a5",
                                 minWidth: "45%"
                             }}
                />
            </div>
        </form>
    )
};

const selector = formValueSelector('paymentForm');// <-- same as form name

const PaymentFormRedux = reduxForm({
    form: 'paymentForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    // enableReinitialize: true,
})(PaymentForm);


const PaymentFormDecorated = connect((state) => {
    const isOnlinePayment = selector(state, `isOnlinePayment`);
    return {
        isOnlinePaymentValue: isOnlinePayment,
        initialValues: {isOnlinePayment: true},

    };
})(PaymentFormRedux);
export default PaymentFormDecorated;
