import React from 'react'
import {Field, formValueSelector, reduxForm, change} from 'redux-form'
import Popup from 'reactjs-popup';
import {connect} from "react-redux";

// Validators
import {
    isAlpha,
    isPhoneNumber,
    isNumber,
    required,
    normalizeCar,
    normalizePhone,
    email
} from '../../../services/validators'

// Styles
import '../../../styles/form.scss';
import './personalForm.scss';
import './membershipForm.scss';

// Components
import InputField from '../../InputFields/InputField';
import CheckBox from '../../InputFields/CheckBox';
import DatePicker from "../../InputFields/DatePicker";
import Autocomplete from "../../InputFields/Autocomplete";
import StyleButton from '../../StyleButton';

// Services
import {dateParse} from "../../../services/utils";
import {getCarBrandAutocomplete, getCarModelAutocomplete} from "../../../services/car";
import Terms from "../../Terms";
import CustomSelect from "../../InputFields/Select";
import moment from "moment";

const ownershipEnum = [
    {
        label: 'פרטי',
        value: 1
    },
    {
        label: 'ליסינג',
        value: 2
    },
    {
        label: 'חברה',
        value: 3
    }
]

let MembershipForm = props => {
    const {handleSubmit, error, invalid, prefix = "", carBrands, maker, model, ownership, updateField, isFromStand, dispatch} = props;
    return (
        <form className="form membershipForm" onSubmit={(form) => handleSubmit(form, dispatch)}>
            {/*<div className="together">
                <Field name={`${prefix}firstName`}
                       type="text"
                       component={InputField}
                       validate={[isAlpha, required]}
                       label="שם פרטי"
                       placeholder="שם פרטי*"
                />
                <Field name={`${prefix}lastName`}
                       type="text"
                       component={InputField}
                       validate={[isAlpha, required]}
                       label="שם משפחה"
                       placeholder="שם משפחה*"
                />
            </div>*/}
            <div className="together">
                <Field name={`${prefix}fullname`}
                       type="text"
                       component={InputField}
                       validate={[isAlpha, required]}
                       label="שם מלא"
                       placeholder="שם מלא*"
                       disabled={isFromStand}
                />
                <Field name={`${prefix}phone`}
                       type="text"
                       component={InputField}
                       validate={[required,isPhoneNumber]}
                       label="טלפון"
                       placeholder="טלפון*"
                       normalize={normalizePhone}
                       format={normalizePhone}
                    // parse={(value, name) => value ? value.split('-').join('') : value}
                />
                <Field name={`${prefix}year`}
                       type="number"
                       style={{direction: 'ltr', textAlign: 'right'}}
                       component={InputField}
                       containerClass={'hidden'}
                       validate={[isNumber]}
                       label="שנת ייצור"
                       placeholder="שנת ייצור"
                />
            </div>
            <div className="together">
                <Field name={`${prefix}number`}
                       type="text"
                       component={InputField}
                       style={{direction: 'ltr', textAlign: 'right'}}
                       validate={[isNumber, required]}
                       label="מספר רכב"
                       placeholder="*מספר רכב"
                       normalize={normalizeCar}
                       // format={normalizeCar}
                       disabled={isFromStand}
                    // parse={(value, name) => value ? value.split('-').join('') : value}
                />
                <Field name={`${prefix}mileage`}
                       type="number"
                       style={{direction: 'ltr', textAlign: 'right'}}
                       component={InputField}
                       validate={[isNumber]}
                       label="קילומטראז׳"
                       placeholder="קילומטראז׳"
                />
            </div>
            <div className="together">
                <Field
                    name="maker"
                    label="יצרן"
                    placeholder="יצרן"
                    showDropdownIndicator={false}
                    onCustomChange={() => updateField('membership-form', 'model', '')}
                    // defaultOptions={getCarBrandAutocomplete('', carBrands)}
                    options={getCarBrandAutocomplete('', carBrands)}
                    // loadOptions={searchToken => getCarBrandAutocomplete(searchToken, carBrands)}
                    noOptionsMessage={() => 'לא נמצאו תוצאות'}
                    validate={[]}
                    // parse={(value, name) => value ? value.label : value}
                    disabled={isFromStand}
                    component={isFromStand ? InputField : CustomSelect}/>
                <Field
                    name="model"
                    label="דגם"
                    placeholder="דגם"
                    showDropdownIndicator={false}
                    isDisabled={!maker}
                    options={maker && typeof maker === 'object' ? getCarModelAutocomplete('', maker, carBrands) : []}
                    // loadOptions={searchToken => getCarModelAutocomplete(searchToken, maker, carBrands)}
                    noOptionsMessage={() => 'לא נמצאו תוצאות'}
                    validate={[]}
                    // parse={(value, name) => value ? value.label : value}
                    disabled={isFromStand}
                    component={isFromStand ? InputField : CustomSelect}/>
            </div>
            <div className="together">
                <Field name={`${prefix}email`}
                       type="text"
                       component={InputField}
                       validate={[email,required]}
                       label="מייל"
                       placeholder="מייל*"
                />
                <Field name={`${prefix}testDate`}
                       component={DatePicker}
                       showTimeSelect={false}
                       dateFormat={"dd/MM/yyyy"}
                       minDate={new Date().setFullYear( new Date().getFullYear() - 1 )}
                       maxDate={new Date()}
                       parse={dateParse}
                       format={dateParse}
                       validate={[]}
                       label="תאריך טסט אחרון"
                       placeholder="תאריך טסט אחרון"
                />
                {/*<Field
                    name="ownership"
                    label="בעלות"
                    placeholder="בעלות*"
                    options={ownershipEnum}
                    onCustomChange={(value) => updateField('membership-form', 'ownership', value)}
                    noOptionsMessage={() => 'לא נמצאו תוצאות'}
                    validate={[required]}
                    component={CustomSelect}/>
                {ownership && ownership.value > 1 ?
                    <Field name={`${prefix}companyName`}
                           type="text"
                           component={InputField}
                           validate={[isAlpha, required]}
                           label="שם החברה"
                           placeholder="שם החברה*"
                    /> : null}*/}
            </div>
            <div className="together required-text">*שדה חובה</div>
            <div className="together required-text">
                <Field name={`${prefix}confirm`}
                       component={CheckBox}
                       validate={[required]}
                       checked={true}
                       disabled={false}
                />
                <span className="required-label">
                    <span>אני מאשר/ת את </span>
                    <Popup trigger={<u className="underline">תנאי השימוש והתקנון</u>} modal closeOnDocumentClick>
                        {close => (
                            <div className="modal">
                                <a className="close" onClick={close}>
                                    &times;
                                </a>
                                <Terms/>
                            </div>
                        )}
                    </Popup>
                    <span> של מועדון הלקוחות של טכנוטסט.</span>
                </span>
            </div>
            {error && <div className="form-error">{error}</div>}
            <StyleButton label='לאישור והרשמה'
                         type="submit"
                         isDisabled={invalid}
                         color='#0067a5'
                         customStyle={{
                             marginTop: "60rem",
                             justifySelf: 'center',
                             alignSelf: 'center',
                             fontSize: "22rem"
                         }}
            />
        </form>
    )
};

const mapDispatchToProps = dispatch => ({
    updateField: (form, field, newValue) => dispatch(change(form, field, newValue))
});

const selector = formValueSelector('membership-form');// <-- same as form name
const MembershipFormRedux = reduxForm({
    form: 'membership-form',
    enableReinitialize: true
})(MembershipForm);
const MembershipFormDecorated = connect((state, {prefix = ""}) => {
    const {car} = state.stand;
    let user = state.stand.user || state.user;
    const carBrands = state.app.enums.cars;
    const maker = selector(state, `${prefix}maker`);
    const model = selector(state, `${prefix}model`);
    // const ownership = selector(state, `${prefix}ownership`);
    return {
        initialValues: {...car, ...user},
        maker: maker ? maker : null,
        // maker: car ? carBrands.find(c=>c.value === car.maker) : null},
        carBrands: carBrands,
        isFromStand: !!(car && user),
        // ownership: ownership
    };
}, mapDispatchToProps)(MembershipFormRedux);


export default MembershipFormDecorated;
